import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-temple-management',
  templateUrl: './about-temple-management.component.html',
  styleUrls: ['./about-temple-management.component.scss'],
})
export class AboutTempleManagementComponent implements OnInit {
  managementData;
  constructor() {}

  ngOnInit(): void {
    this.managementData = history.state;
  }
}
