import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home-songs-list',
  templateUrl: './home-songs-list.component.html',
  styleUrls: ['./home-songs-list.component.scss'],
})
export class HomeSongsListComponent implements OnInit {
  sub: Subscription;
  songsList = [];
  noData;
  pageSize = 12;
  page = 1;
  finished = false;
  notEmpty = true;
  notScrolly = true;
  globalId;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    public spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.sub = this.route.paramMap.subscribe((params) => {
      let id = params.get('id');
      this.globalId = id;
      this.getSongList(this.pageSize, this.page, id);
    });
  }
  getSongList(pageSize, page, id) {
    return this.http
      .get(
        environment.rootUrl +
          'songs/?category__id=' +
          id +
          '&pageSize=' +
          pageSize +
          '&page=' +
          page
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.noData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.notEmpty = false;
          } else {
            this.songsList = this.songsList.concat(newPost);
            this.notScrolly = true;
          }
        },
        (error) => {
          this.notEmpty = false;
          this.spinner.hide();
        }
      );
  }
  onScroll() {
    this.page += 1;
    if (this.notScrolly && this.notEmpty) {
      this.spinner.show();
      this.notScrolly = false;
      this.getSongList(this.pageSize, this.page, this.globalId);
    }
  }
}
