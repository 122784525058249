import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrivacyService {
  constructor(private http: HttpClient) {}
  getData(title) {
    return this.http.get(environment.rootUrl + 'hktc-data/?title=' + title);
  }
}
