import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isLoading = false;
  constructor(private fb: FormBuilder, private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  passwordForm = this.fb.group({
    email: ['',[Validators.email,Validators.required]]
  })
  forgotPassword() {
    if (this.passwordForm.valid) {
      this.isLoading = true;
      let body = {
        email: this.passwordForm.value.email
      }
      this.http.post(environment.rootUrl + "forgot-password/", body).subscribe((res: any) => {
        this.isLoading = false;
        this.toastr.success(res.success, "Success")
        this.passwordForm.reset()
      },
        (error: any) => {
          this.isLoading = false;
          this.toastr.error(error.error, "Error")
        })
    }
  }

}
