import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router,public loginService: LoginService, private http: HttpClient) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (localStorage.getItem('token') != null) {
      let loginObj: any = this.loginService.loginObject();
      //if(Date.now() < Number(loginObj.expiration * 1000)){
      const clonedReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token')) })
      return next.handle(clonedReq).pipe(tap(succ => { }, err => {
          if (err.status == 401) {
              let body = {
                  "refresh":localStorage.getItem("refresh")
              }
              this.http.post(environment.rootUrl+"token/refresh/",body).subscribe((res:any)=>{
                  localStorage.setItem("token",res.access)
              },(error:any)=>{
                  this.loginService.resetSecurityObject()
                  this.router.navigate(['/login'])
              })
              next.handle(req.clone())
          }
      }))
  } else {
      return next.handle(req.clone())
  }
    // if (localStorage.getItem('adminToken') != null) {
    //   const clonedReq = req.clone({
    //     // headers: req.headers.set(
    //     //   'Authorization',
    //     //   'Bearer ' + localStorage.getItem('adminToken')
    //     // ),
    //     setHeaders: {
    //       Authorization: 'Bearer ' + localStorage.getItem('adminToken'),
    //     },
    //   });
    //   return next.handle(clonedReq).pipe(
    //     tap(
    //       (succ) => {},
    //       (err) => {
    //         if (err.status == 401) localStorage.removeItem('adminToken');
    //         this.router.navigateByUrl('/login');
    //       }
    //     )
    //   );
    // } else return next.handle(req.clone());
  }
}
