import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  isLoading = false;
  token;
  uid;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private http: HttpClient, private toastr: ToastrService, private router: Router, public loginService: LoginService) { 
    this.route.queryParams.subscribe((params) => {
      this.token = params['token']
      this.uid = params['uid']
    })
  }
  validationMessages = {
    "password": {
      'required': 'This field is required',
      'minlength': 'Password is too short, a minimum of 8 character is required'
    },
    "confirmPassword": {
      'required': 'This field is required',
      'minlength': 'Password is too short, a minimum of 8 character is required'
    }
  }
  formErrors = {
    'password': '',
    'confirmPassword': ''
  }

  ngOnInit(): void {
  }
  resetPasswordForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
  })
  resetPassword() {
    this.loginService.logValidationErrors(this.resetPasswordForm, this.formErrors, this.validationMessages)
    if (this.resetPasswordForm.valid) {
      if (this.resetPasswordForm.value.password == this.resetPasswordForm.value.confirmPassword) {
        this.isLoading = true;
        let body = {
          token: this.token,
          uid: this.uid,
          password: this.resetPasswordForm.value.password
        }
        this.http.post(environment.rootUrl + "password-reset/", body).subscribe((res: any) => {
          this.isLoading = false;
          this.toastr.success(res.success, "Password reset")
          this.resetPasswordForm.reset()
          this.router.navigate(['/'])
        },
          (error: any) => {
            this.isLoading = false;
            this.toastr.error(error.error, "Error")
          })
      }else{
        this.toastr.error("The password fields do not match!","Error")
      }
    }
  }

}
