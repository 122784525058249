<div class="container-fluid">
  <div class="row">
    <div class="col header">
      <div class="header-section">
        <h1 class="header-title">{{ eventsData.event_name }}</h1>
      </div>
    </div>
  </div>
</div>
<div class="container" *ngIf="eventsData">
  <div class="row">
    <div class="col-12 remove-container-padding">
      <img
        src="{{ eventsData.event_photo }}"
        alt=""
        class="img-fluid img-100"
      />
      <h3 class="remove-header-spaces faded-description">
        <i
          >{{ eventsData.start_date | date: "full" }} -
          {{ eventsData.end_date | date: "full" }}</i
        >
      </h3>
      <h3 class="remove-header-spaces faded-description">
        <i
          ><b
            ><i class="fa fa-map-marker" aria-hidden="true"></i
            >{{ eventsData.location }}</b
          ></i
        >
      </h3>
    </div>
    <div class="col-12" [innerHTML]="eventsData.details"></div>
    <div class="col-12" style="display: flex; justify-content: center">
      <a
        routerLink="../events"
        class="btn btn-outline-success btn-md"
        data-aos="zoom-out-up"
      >
        <i class="fas fa-arrow-left ml-1"></i>Back to festivals
      </a>
    </div>
  </div>
</div>
<div class="container" *ngIf="!eventsData">
  <div class="row">
    <div class="col no-data">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
