<div class="container-fluid">
  <div class="row">
    <div class="col header">
      <div class="header-section">
        <h1 class="header-title">Events/Festivals</h1>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col">
      <mat-tab-group>
        <mat-tab label="Festivals">
          <ng-template matTabContent>
            <div
              class="container-fluid"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="onScrollFestivals()"
              *ngIf="festivalsList.length"
            >
              <div class="row">
                <div
                  class="col-lg-3 col-md-4 col-sm-6 remove-padding-images navigation-card"
                  *ngFor="let festival of festivalsList"
                  (click)="festivalService.festivalsDetails(festival)"
                >
                  <mdb-card>
                    <mdb-card-img src="{{ festival.photo }}"></mdb-card-img>
                    <mdb-card-body>
                      <mdb-card-title>
                        <h3 class="no-wrap remove-header-spaces">
                          {{ festival.name }}
                        </h3>
                        <h4
                          *ngIf="festival.start_date"
                          class="faded-description remove-header-spaces"
                        >
                          {{ festival.start_date | date: "fullDate" }} -
                          {{ festival.end_date | date: "fullDate" }}
                        </h4>
                        <h4
                          *ngIf="!festival.start_date"
                          class="faded-description remove-header-spaces"
                        >
                          {{
                            festival.period.length > 100
                              ? (festival.period | slice: 0:100) +
                                "...Read more"
                              : festival.period
                          }}
                        </h4>
                        <button
                          class="btn btn-outline-success btn-md more-btn"
                          data-aos="zoom-out-up"
                        >
                          Read more
                          <i class="fas fa-arrow-right ml-1"></i>
                        </button>
                      </mdb-card-title>
                    </mdb-card-body>
                  </mdb-card>
                </div>
                <div class="col-12 no-data" *ngIf="noData?.count == 0">
                  <h3 class="no-data-txt">No record found</h3>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ngx-spinner
                    bdOpacity="0.9"
                    bdColor=""
                    size="default"
                    color="#581f87"
                    type="ball-pulse-sync"
                    [fullScreen]="false"
                  ></ngx-spinner>
                </div>
              </div>
            </div>
            <div *ngIf="!festivalsList?.length" class="container-fluid">
              <div class="row">
                <div class="col no-data">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Upcoming Events">
          <ng-template matTabContent>
            <div
              class="container-fluid"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="onScrollEvents()"
              *ngIf="eventsList.length"
            >
              <div class="row">
                <div
                  class="col-lg-3 col-md-4 col-sm-6 remove-padding-images navigation-card"
                  *ngFor="let event of eventsList"
                  (click)="festivalService.eventsDetails(event)"
                >
                  <mdb-card>
                    <mdb-card-img src="{{ event.event_photo }}"></mdb-card-img>
                    <mdb-card-body>
                      <mdb-card-title>
                        <h3 class="no-wrap event-headers">
                          {{ event.event_name }}
                        </h3>
                        <h4 class="faded-description no-wrap event-headers">
                          {{ event.start_date | date: "full" }} -
                          {{ event.end_date | date: "full" }}
                        </h4>
                        <h4 class="faded-description no-wrap event-headers">
                          <i
                            ><b
                              ><i
                                class="fa fa-map-marker"
                                aria-hidden="true"
                              ></i>
                              {{ event.location }}</b
                            ></i
                          >
                        </h4>
                        <button
                          class="btn btn-outline-success btn-md more-btn"
                          data-aos="zoom-out-up"
                        >
                          Read more
                          <i class="fas fa-arrow-right ml-1"></i>
                        </button>
                      </mdb-card-title>
                    </mdb-card-body>
                  </mdb-card>
                </div>
                <div class="col-12 no-data" *ngIf="noData?.count == 0">
                  <h3 class="no-data-txt">No record found</h3>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ngx-spinner
                    bdOpacity="0.9"
                    bdColor=""
                    size="default"
                    color="#581f87"
                    type="ball-pulse-sync"
                    [fullScreen]="false"
                  ></ngx-spinner>
                </div>
              </div>
            </div>
            <div *ngIf="!eventsList.length" class="container-fluid">
              <div class="row">
                <div class="col no-data">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
