import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-outreach',
  templateUrl: './outreach.component.html',
  styleUrls: ['./outreach.component.scss'],
})
export class OutreachComponent implements OnInit {
  outreachData;
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getHktc();
  }
  getHktc() {
    this.http
      .get(environment.rootUrl + 'hktc-data/?title=outreach')
      .subscribe((res: any) => {
        this.outreachData = res.results[0];
      });
  }
}
