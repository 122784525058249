import { Component, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { HomeService } from './home.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import AOS from 'aos';
import { BlockScrollStrategy } from '@angular/cdk/overlay';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('openCloseMenu', [
      state(
        'openMenu',
        style({
          position: 'fixed',
          top: '0px',
          left: '0px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
          backgroundColor: '#22313F',
          zIndex: 5000,
          overflow: 'scroll',
        })
      ),
      state(
        'closeMenu',
        style({
          display: 'none',
        })
      ),
      transition('openMenu <=> closeMenu', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  constructor(
    public homeService: HomeService,
    public spinner: NgxSpinnerService
  ) {}
  isOpen = false;
  openNav() {
    this.isOpen = !this.isOpen;
  }

  ngOnInit(): void {
    AOS.init();
    this.homeService.getHomePageContent();
    this.spinner.show();
  }
}
