<div class="container-fluid login-section">
    <div class="row">
        <div class="col">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="login-content-section">
                            <div class="login-form-section">
                                <h1 class="login-header">Forgot password</h1>
                                <div class="form-section">
                                    <p class="form-text-header"><small>Please enter your registered email</small></p>
                                    <form [formGroup]="passwordForm">
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-form-field class="mat-input-fields">
                                                    <mat-label>Email address</mat-label>
                                                    <input matInput placeholder="Email" formControlName="email"
                                                        required>
                                                    <mat-icon matPrefix>email</mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-12">
                                                <button mat-raised-button color="primary" class="form-control login-btn"
                                                    (click)="forgotPassword()">
                                                    <span>Send email</span>
                                                    <!-- <i *ngIf="isLoading">Please wait...</i> -->
                                                </button>
                                                <br>
                                                <br>
                                                <a routerLink="/">Back to Login page</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="loading-content" *ngIf="isLoading">
                                <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>