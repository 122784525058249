import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-songs',
  templateUrl: './home-songs.component.html',
  styleUrls: ['./home-songs.component.scss'],
})
export class HomeSongsComponent implements OnInit {
  songsCategories;
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getSongsCategories();
  }
  getSongsCategories() {
    this.http
      .get(environment.rootUrl + 'song-categories/?pageSize=150')
      .subscribe((res: any) => {
        this.songsCategories = res.results;
      });
  }
}
