<div class="container-fluid">
  <div class="row">
    <div class="col header">
      <div class="header-section">
        <h1 class="header-title no-wrap">
          International Society for Krishna Consciousness (ISKCON)
        </h1>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col">
      <mat-tab-group>
        <mat-tab label="About ISKCON">
          <ng-template matTabContent>
            <div class="container-fluid remove-container-padding">
              <div class="row">
                <div class="col-12 remove-container-padding">
                  <img
                    src="{{ iskconData.photo }}"
                    alt=""
                    class="img-fluid banner-image"
                    *ngIf="iskconData.photo"
                  />
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <!-- <div class="container"> -->
              <div class="row" *ngIf="iskconData">
                <div class="col-12" style="padding: 0px"></div>
                <div
                  class="col-12"
                  [innerHtml]="iskconData.details"
                  *ngIf="iskconData.details"
                ></div>
                <div class="col-12 no-data" *ngIf="!iskconData?.details">
                  <h2 class="no-data-txt">No record available!</h2>
                </div>
              </div>
              <!-- </div> -->
            </div>
            <div class="container-fluid" *ngIf="!iskconData">
              <div class="row">
                <div class="col-12 no-data">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Management">
          <ng-template matTabContent>
            <div
              class="container-fluid"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="managementScroll()"
            >
              <div class="row" *ngIf="managementList">
                <div
                  class="col-lg-2 col-md-3 col-sm-4 col-6 navigation-card"
                  *ngFor="let record of managementList"
                  (click)="managementDetails(record)"
                >
                  <mdb-card>
                    <mdb-card-img
                      src="{{ record.profile_photo }}"
                    ></mdb-card-img>
                    <mdb-card-body>
                      <mdb-card-title>
                        <h3 style="margin: 0px">
                          {{ record.full_name }}
                        </h3>
                        <h4 style="color: #808080; margin: 0px">
                          {{ record.title }}
                        </h4>
                        <button class="btn btn-outline-success btn-md">
                          More about
                          <i class="fas fa-arrow-right ml-1"></i>
                        </button>
                      </mdb-card-title>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </div>
              <div class="row" *ngIf="managementData?.count == 0">
                <div class="col no-data">
                  <h2><i>No record found!</i></h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ngx-spinner
                    bdOpacity="0.9"
                    bdColor=""
                    size="default"
                    color="#581f87"
                    type="ball-pulse-sync"
                    [fullScreen]="false"
                  ></ngx-spinner>
                </div>
              </div>
            </div>
            <div class="container" *ngIf="!managementData">
              <div class="row">
                <div class="col no-data">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="News & Activities">
          <ng-template matTabContent>
            <div
              class="container-fluid"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="newsScroll()"
              *ngIf="newsList.length"
            >
              <div class="row">
                <div
                  class="col-lg-3 col-md-4 col-sm-6 remove-padding-images navigation-card"
                  *ngFor="let event of newsList"
                  (click)="newsDetails(event)"
                >
                  <mdb-card>
                    <mdb-card-img src="{{ event.photo }}"></mdb-card-img>
                    <mdb-card-body>
                      <mdb-card-title>
                        <h3 class="no-wrap event-headers">
                          {{ event.title }}
                        </h3>
                        <h4 class="faded-description no-wrap event-headers">
                          {{ event.created_date | date: "full" }}
                        </h4>
                        <h4 class="faded-description no-wrap event-headers">
                          <i
                            ><b> By: {{ event.author }}</b></i
                          >
                        </h4>
                        <button
                          class="btn btn-outline-success btn-md more-btn"
                          data-aos="zoom-out-up"
                        >
                          Read more
                          <i class="fas fa-arrow-right ml-1"></i>
                        </button>
                      </mdb-card-title>
                    </mdb-card-body>
                  </mdb-card>
                </div>
                <div class="col-12 no-data" *ngIf="newsData?.count == 0">
                  <h3 class="no-data-txt">No record found</h3>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ngx-spinner
                    bdOpacity="0.9"
                    bdColor=""
                    size="default"
                    color="#581f87"
                    type="ball-pulse-sync"
                    [fullScreen]="false"
                  ></ngx-spinner>
                </div>
              </div>
            </div>
            <div *ngIf="!newsData" class="container-fluid">
              <div class="row">
                <div class="col no-data">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Gallery">
          <ng-template matTabContent>
            <div
              class="container-fluid"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="galleryScroll()"
            >
              <div class="row" *ngIf="galleryList">
                <div
                  class="col-lg-3 col-md-4 col-sm-6 remove-padding-images"
                  *ngFor="let record of galleryList"
                >
                  <mdb-card>
                    <mdb-card-img src="{{ record.photo }}"></mdb-card-img>
                    <mdb-card-body>
                      <mdb-card-title>
                        <h3 style="margin: 0px" *ngIf="record.description">
                          {{ record.details }}
                        </h3>
                        <h4 style="color: #808080; margin: 0px">
                          {{ record.created_date | date: "full" }}
                        </h4>
                      </mdb-card-title>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </div>
              <div class="row" *ngIf="noData?.count == 0">
                <div class="col no-data">
                  <h2><i>No record found!</i></h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ngx-spinner
                    bdOpacity="0.9"
                    bdColor=""
                    size="default"
                    color="#581f87"
                    type="ball-pulse-sync"
                    [fullScreen]="false"
                  ></ngx-spinner>
                </div>
              </div>
            </div>
            <div class="container" *ngIf="!noData">
              <div class="row">
                <div class="col no-data">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
