import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AngularEpubViewerComponent } from 'angular-epub-viewer';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-books',
  templateUrl: './home-books.component.html',
  styleUrls: ['./home-books.component.scss'],
})
export class HomeBooksComponent implements OnInit {
  booksList = [];
  noData;
  pageSize = 12;
  page = 1;
  finished = false;
  notEmpty = true;
  notScrolly = true;
  constructor(
    private http: HttpClient,
    public spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getBooksList(this.pageSize, this.page);
  }
  openBook(book) {
    this.router.navigateByUrl('/home/read-book', { state: book });
    // this.epubViewer.openLink(book);
  }
  getBooksList(pageSize, page) {
    return this.http
      .get(
        environment.rootUrl + 'books/?pageSize=' + pageSize + '&page=' + page
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.noData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.notEmpty = false;
          } else {
            this.booksList = this.booksList.concat(newPost);
            this.notScrolly = true;
          }
        },
        (error) => {
          this.notEmpty = false;
          this.spinner.hide();
        }
      );
  }
  onScroll() {
    this.page += 1;
    if (this.notScrolly && this.notEmpty) {
      this.spinner.show();
      this.notScrolly = false;
      this.getBooksList(this.pageSize, this.page);
    }
  }
}
