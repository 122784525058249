import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/home/home.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-app-page-dialog',
  templateUrl: './app-page-dialog.component.html',
  styleUrls: ['./app-page-dialog.component.scss'],
})
export class AppPageDialogComponent implements OnInit {
  displayFile;
  fileToUpload;
  constructor(
    public homeService: HomeService,
    private http: HttpClient,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}
  handleFileInput(event) {
    let img = event.target.files[0];
    let imgName = 'Prabhupada_' + new Date().getTime();
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event: any) => {
      this.displayFile = img;
    };
    this.fileToUpload = img;
  }
  saveAppPageContent() {
    const formData = new FormData();
    formData.append('title', this.homeService.appPageContentForm.value.title);
    if (this.fileToUpload) {
      formData.append('photo', this.fileToUpload, this.fileToUpload.name);
    }
    formData.append('rank', this.homeService.appPageContentForm.value.rank);
    if (this.homeService.appPageContentForm.value.id) {
      this.http
        .patch(
          environment.rootUrl +
            'app-page-content/' +
            this.homeService.appPageContentForm.value.id +
            '/',
          formData
        )
        .subscribe((res) => {
          this.toastr.info('Record saved successfully', 'App page content');
          this.homeService.appPageContentForm.reset();
          this.homeService.getMobileAppContent();
          this.dialog.closeAll();
        });
    } else {
      this.http
        .post(environment.rootUrl + 'app-page-content/', formData)
        .subscribe((res) => {
          this.toastr.success('Record saved successfully', 'App page content');
          this.homeService.appPageContentForm.reset();
          this.homeService.getMobileAppContent();
          this.dialog.closeAll();
        });
    }
  }
}
