<div class="container-fluid">
  <div class="row">
    <div class="col header">
      <div class="header-section">
        <h1 class="header-title" *ngIf="templeData">
          {{ templeData.temple_name }}
        </h1>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col remove-container-padding">
      <mat-tab-group>
        <mat-tab label="About Temple">
          <ng-template matTabContent>
            <div class="container-fluid" *ngIf="templeData">
              <div class="row">
                <div class="col-12 remove-container-padding">
                  <img
                    src="{{ templeData.profile_photo }}"
                    class="img-fluid img-100"
                  />
                </div>
              </div>
            </div>
            <div class="container-fluid" *ngIf="templeData">
              <div class="row">
                <div class="col-12" [innerHTML]="templeData.details"></div>
                <div class="col-12 no-data" *ngIf="!templeData.details">
                  <h2 class="no-data-txt">No record available</h2>
                </div>
              </div>
            </div>
            <div class="container-fluid" *ngIf="!templeData">
              <div class="row">
                <div class="col-12 no-data">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Management">
          <ng-template matTabContent>
            <div
              class="container-fluid"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="managementScroll()"
            >
              <div class="row" *ngIf="managementList">
                <div
                  class="col-lg-2 col-md-3 col-sm-4 col-6 navigation-card"
                  *ngFor="let record of managementList"
                  (click)="managementDetails(record)"
                >
                  <mdb-card>
                    <mdb-card-img
                      src="{{ record.profile_photo }}"
                    ></mdb-card-img>
                    <mdb-card-body>
                      <mdb-card-title>
                        <h3 style="margin: 0px">
                          {{ record.full_name }}
                        </h3>
                        <h4 style="color: #808080; margin: 0px">
                          {{ record.title }}
                        </h4>
                        <button class="btn btn-outline-success btn-md">
                          More about
                          <i class="fas fa-arrow-right ml-1"></i>
                        </button>
                      </mdb-card-title>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </div>
              <div class="row" *ngIf="managementData?.count == 0">
                <div class="col no-data">
                  <h2><i>No record found!</i></h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ngx-spinner
                    bdOpacity="0.9"
                    bdColor=""
                    size="default"
                    color="#581f87"
                    type="ball-pulse-sync"
                    [fullScreen]="false"
                  ></ngx-spinner>
                </div>
              </div>
            </div>
            <div class="container" *ngIf="!managementData">
              <div class="row">
                <div class="col no-data">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Properties">
          <ng-template matTabContent>
            <div
              class="container-fluid"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="onScroll()"
            >
              <div class="row" *ngIf="propertyList">
                <div
                  class="col-lg-3 col-md-4 col-sm-6 remove-padding-images"
                  *ngFor="let record of propertyList"
                >
                  <mdb-card>
                    <mdb-card-img
                      src="{{ record.property_photo }}"
                    ></mdb-card-img>
                    <mdb-card-body>
                      <mdb-card-title>
                        <h3 style="margin: 0px">
                          {{ record.property_name }}
                        </h3>
                      </mdb-card-title>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </div>
              <div class="row" *ngIf="propertyData?.count === 0">
                <div class="col no-data">
                  <h2><i>No record found!</i></h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ngx-spinner
                    bdOpacity="0.9"
                    bdColor=""
                    size="default"
                    color="#581f87"
                    type="ball-pulse-sync"
                    [fullScreen]="false"
                  ></ngx-spinner>
                </div>
              </div>
            </div>
            <div class="container" *ngIf="!propertyData">
              <div class="row">
                <div class="col no-data">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Training Centers">
          <ng-template matTabContent>
            <div
              class="container-fluid"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="onScroll()"
            >
              <div class="row" *ngIf="hktcList">
                <div
                  class="col-lg-3 col-md-4 col-sm-6 remove-padding-images"
                  *ngFor="let record of hktcList"
                  data-aos="flip-down"
                >
                  <a [routerLink]="['../../training-centres', record.id]">
                    <mdb-card>
                      <mdb-card-img
                        src="{{ record.profile_photo }}"
                      ></mdb-card-img>
                      <mdb-card-body>
                        <mdb-card-title>
                          <h3 style="margin: 0px">
                            {{ record.name }} (<i>{{
                              record.temple.temple_name
                            }}</i
                            >)
                          </h3>
                          <h4 style="color: #808080; margin: 0px">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            {{ record.locality }} (<i
                              >{{ record.temple.city }},
                              {{ record.temple.country }}</i
                            >)
                          </h4>
                          <button class="btn btn-outline-success btn-md">
                            View more
                            <i class="fas fa-arrow-right ml-1"></i>
                          </button>
                        </mdb-card-title>
                      </mdb-card-body>
                    </mdb-card>
                  </a>
                </div>
              </div>
              <div class="row" *ngIf="hktcData?.count == 0">
                <div class="col no-data">
                  <h2><i>No record found!</i></h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ngx-spinner
                    bdOpacity="0.9"
                    bdColor=""
                    size="default"
                    color="#581f87"
                    type="ball-pulse-sync"
                    [fullScreen]="false"
                  ></ngx-spinner>
                </div>
              </div>
            </div>
            <div class="container" *ngIf="!hktcData">
              <div class="row">
                <div class="col no-data">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Gallery">
          <ng-template matTabContent>
            <div
              class="container-fluid"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="onScroll()"
            >
              <div class="row" *ngIf="galleryList">
                <div
                  class="col-lg-3 col-md-4 col-sm-6 remove-padding-images"
                  *ngFor="let record of galleryList"
                >
                  <mdb-card>
                    <mdb-card-img src="{{ record.photo }}"></mdb-card-img>
                    <mdb-card-body>
                      <mdb-card-title>
                        <h3 style="margin: 0px" *ngIf="record.description">
                          {{ record.description }}
                        </h3>
                        <h4 style="color: #808080; margin: 0px">
                          {{ record.created_date | date: "full" }}
                        </h4>
                      </mdb-card-title>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </div>
              <div class="row" *ngIf="noData?.count == 0">
                <div class="col no-data">
                  <h2><i>No record found!</i></h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ngx-spinner
                    bdOpacity="0.9"
                    bdColor=""
                    size="default"
                    color="#581f87"
                    type="ball-pulse-sync"
                    [fullScreen]="false"
                  ></ngx-spinner>
                </div>
              </div>
            </div>
            <div class="container" *ngIf="!noData">
              <div class="row">
                <div class="col no-data">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
