<div class="container-fluid">
  <div class="row">
    <div class="col header">
      <div class="header-section">
        <h1 class="header-title" *ngIf="bookData">{{ bookData.title }}</h1>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div
      class="col-12"
      style="display: flex; justify-content: center; padding: 10px"
    >
      <button (click)="epubViewer.previousPage()">Previous page</button>
      <button (click)="epubViewer.nextPage()">Next page</button>
    </div>
    <div class="col-12">
      <angular-epub-viewer #epubViewer></angular-epub-viewer>
    </div>
    <div class="col-12" style="display: flex; justify-content: center">
      <a
        routerLink="../books"
        class="btn btn-outline-success btn-md"
        data-aos="zoom-out-up"
      >
        <i class="fas fa-arrow-left ml-1"></i>Back to books
      </a>
    </div>
  </div>
</div>
