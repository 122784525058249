import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { TemplesService } from '../admin/temples/temples.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionsContainer } from '../subscription.container';
import { MatSelect } from '@angular/material/select';
import { RegistrationService} from './registration.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-devotee-register',
  templateUrl: './devotee-register.component.html',
  styleUrls: ['./devotee-register.component.scss'],
})
export class DevoteeRegisterComponent implements OnInit {
  isLoading = false;
  fileToUpload;
  displayFile;
  roomsList;
  roomsFilterList
  subscriptions = new SubscriptionsContainer()
  roomsFilterCtrl:FormControl = new FormControl();
  @ViewChild('singleSelect',{static:true})singleSelect: MatSelect
  constructor(
    private fb: UntypedFormBuilder,
    public templeService: TemplesService,
    private http: HttpClient,
    private toastr: ToastrService,
    public registrationService : RegistrationService,
    private router : Router
  ) {}

  ngOnInit(): void {
    this.templeService.getHktc();
   // this.getRooms(this.devoteeForm.value.hktc);
    this.roomsFilterCtrl.valueChanges.subscribe(()=>{
      this.filterRooms()
    })
  }
  selectCenter(id){
    this.getRooms(id);
  }
  getRooms(centreId){
    this.subscriptions.add = this.registrationService.getRooms(centreId).subscribe((res:any)=>{
      this.roomsFilterList = res;
      this.roomsList = res
    },
    (error:any)=>{
      this.toastr.error("Error loading branches","Error!")
    })
  }
  protected filterRooms(){
    if(!this.roomsFilterList){
      return;
    }
    let search = this.roomsFilterCtrl.value
    if(!search){
      this.roomsFilterList = this.roomsList
      //this.filterBranchList.next(this.branchList.slice())
    }else{
      search = search.toLowerCase();
    }
    this.roomsFilterList = this.roomsFilterList.filter(room => room.name.toLowerCase().indexOf(search) > -1).slice(0);
    // this.filterBranchList.next(
    //   this.branchList.filter(branch => branch.name.toLowerCase().indexOf(search) > -1)
    // )
  }
  devoteeForm = this.fb.group({
    fullName: ['',[Validators.required]],
    hktc: ['',[Validators.required]],
    email: ['',[Validators.required]],
    idNo:['',[Validators.required]],
    room:['',[Validators.required]],
    guardian: [''],
    guardianPhone: [''],
    phone: [''],
    gender: [''],
    password: ['',[Validators.required]],
    reportingDate:['',[Validators.required]],
    institution:[''],
    course:[''],
    currentYear:['',[Validators.required]]
  });
  handleImageUpload(event) {
    let img = event.target.files[0];
    let imgName = 'Prabhupada_' + new Date().getTime();
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event: any) => {
      this.displayFile = event.target.result;
    };
    this.fileToUpload = img;
  }
  saveDevotees() {
    this.isLoading = true;
    const formData = new FormData();
    let reportDate;
    if(this.devoteeForm.value.reportingDate){
      reportDate = new Date(this.devoteeForm.value.reportingDate)
      reportDate = new Date(reportDate.getTime() - reportDate.getTimezoneOffset()*60000).toISOString()
    }
    formData.append('hktc', this.devoteeForm.value.hktc);
    formData.append('full_name', this.devoteeForm.value.fullName);
    if(this.fileToUpload){
      formData.append('profile_photo', this.fileToUpload, this.fileToUpload.name);
    }
    formData.append('email', this.devoteeForm.value.email);
    formData.append('phone_no', this.devoteeForm.value.phone);
    formData.append('gender', this.devoteeForm.value.gender);
    formData.append('guardian', this.devoteeForm.value.guardian);
    formData.append('guardian_phone', this.devoteeForm.value.guardianPhone);
    formData.append('password', this.devoteeForm.value.password);
    formData.append('room',this.devoteeForm.value.room)
    formData.append('id_no',this.devoteeForm.value.idNo)
    formData.append('report_date',moment(reportDate).format("YYYY-MM-DD"))
    formData.append('last_payment_date',reportDate)
    formData.append('institution',this.devoteeForm.value.institution)
    formData.append('course',this.devoteeForm.value.course)
    formData.append('current_year',this.devoteeForm.value.currentYear)
    formData.append('role','2')
    this.http
      .post(environment.rootUrl + 'users/', formData)
      .subscribe((res) => {
        this.isLoading = false;
        this.toastr.success('Record saved successfully', 'Devotees');
        this.devoteeForm.reset();
        this.router.navigate(['/login'])
      },
      (error:any)=>{
        this.isLoading = false;
        this.toastr.error("Request failed, please ensure all details have been entered correctly!","Error!")
      });
  }
}
