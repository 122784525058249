import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HomeService } from '../home.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  hktcList;
  galleryList;
  videoUrl;
  constructor(
    private http: HttpClient,
    public homeService: HomeService,
    private sanitizer: DomSanitizer
  ) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.homeService.homePageContent[1]['video']
    );
  }

  ngOnInit(): void {
    AOS.init();
    this.getCentres();
    this.getGallery();
    this.homeService.getHomePageContent();
  }
  getCentres() {
    this.http
      .get(environment.rootUrl + 'hktc/?pageSize=3')
      .subscribe((res: any) => {
        this.hktcList = res.results;
      });
  }
  getGallery() {
    this.http
      .get(
        environment.rootUrl + 'hktc-gallery/?hktc__slug=hktc-nairobi&pageSize=3'
      )
      .subscribe((res: any) => {
        this.galleryList = res.results;
      });
  }
}
