<div class="container-fluid">
  <div class="row">
    <div class="col header">
      <div class="header-section">
        <h1 class="header-title">Songs/Prayers Categories</h1>
      </div>
    </div>
  </div>
</div>
<div class="container remove-container-padding" *ngIf="songsCategories">
  <div class="row">
    <div class="col-12 remove-container-padding">
      <ul class="list-group">
        <a
          [routerLink]="['../songs-categories', item.id]"
          class="list-group-item"
          *ngFor="let item of songsCategories"
          >{{ item.category_name }}</a
        >
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="songsCategories.length == 0">
    <div class="col-12">
      <h2 class="no-data-txt">No record available</h2>
    </div>
  </div>
</div>
<div class="container" *ngIf="!songsCategories">
  <div class="row">
    <div class="col no-data">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
