import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  hktcData;
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getHktc();
  }
  getHktc() {
    this.http
      .get(environment.rootUrl + 'hktc/?slug=hktc-nairobi')
      .subscribe((res: any) => {
        this.hktcData = res.results[0];
      });
  }
}
