<div class="login-section-background">
  <div class="login-section">
    <div class="login-form-section">
      <div class="login-header">
        <h2>Registration Form</h2>
        <!-- {{ homeService.schoolData | json}} -->
        <img src="../../assets/images/logo.png" alt="Logo" class="logo" />
      </div>
      <form [formGroup]="devoteeForm" autocomplete="off">
        <div class="row">
          <div class="col-12">
            <mat-form-field class="mat-input-fields" appearance="fill">
              <mat-label>Select training centre</mat-label>
              <mat-select formControlName="hktc" required>
                <mat-option *ngFor="let t of templeService.hktcList" [value]="t.id"
                  (onSelectionChange)="selectCenter(t.id)">{{ t.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="mat-input-fields">
              <input matInput formControlName="fullName" placeholder="Enter full name" required />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="mat-input-fields">
              <input matInput formControlName="idNo" placeholder="ID Number" required />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <mat-form-field class="mat-input-fields">
                <input matInput formControlName="phone" placeholder="Enter phone number" required />
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <mat-form-field class="mat-input-fields">
                <input matInput formControlName="email" placeholder="Enter email address" />
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="mat-input-fields">
              <mat-label>Select room</mat-label>
              <mat-select formControlName="room" required>
                <mat-option *ngFor="let room of roomsList?.results" [value]="room.id">RM {{ room.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <mat-form-field class="mat-input-fields">
                <input matInput formControlName="institution" placeholder="Enter your college/university name" />
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <mat-form-field class="mat-input-fields">
                <input matInput formControlName="course" placeholder="Enter your course / area of study" />
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="mat-input-fields">
              <mat-label>Select current year of study</mat-label>
              <mat-select formControlName="currentYear" required>
                <mat-option value="1">Year 1</mat-option>
                <mat-option value="2">Year 2</mat-option>
                <mat-option value="3">Year 3</mat-option>
                <mat-option value="4">Year 4</mat-option>
                <mat-option value="5">Year 5</mat-option>
                <mat-option value="6">Year 6</mat-option>
                <mat-option value="7">Year 7</mat-option>
                <mat-option value="8">Year 8</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="mat-input-fields">
              <mat-label>Reporting date (MM/DD/YYYY)</mat-label>
              <input matInput [matDatepicker]="startDatePicker" formControlName="reportingDate">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <mat-form-field class="mat-input-fields">
                <input type="password" matInput formControlName="password" placeholder="Enter password" />
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">Select gender</label><br />
              <mat-radio-group aria-label="Select gender" formControlName="gender">
                <mat-radio-button value="Male">Male</mat-radio-button>
                <mat-radio-button value="Female">Female</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">Upload a passport photo</label>
              <input type="file" class="form-control" (change)="handleImageUpload($event)" />
            </div>
          </div>
          <!-- <div>
            <img [src]="displayFile" alt="" class="display-image" />
          </div> -->

        </div>
        <!-- <div class="form-group">
          <mat-form-field class="mat-input-fields">
            <input matInput formControlName="guardian" placeholder="Enter guardian name" />
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field class="mat-input-fields">
            <input matInput formControlName="guardianPhone" placeholder="Enter guardian phone number" />
          </mat-form-field>
        </div> -->

        <div class="form-group">
          <button type="submit" class="form-control btn btn-success registerBtn" (click)="saveDevotees()" [disabled]="!devoteeForm.valid">
            Register
          </button>
        </div>
        <div class="form-group">
          <a routerLink="/login"><button type="button" class="btn btn-outline-primary form-control registerBtn">
              Login
            </button></a>
        </div>
      </form>
      <div class="loading-content" *ngIf="isLoading">
        <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
      </div>
      <div class="fgPart">
        <span><i><a href="">Forgot password?</a></i></span>
        <span><i><a routerLink="/admin">Back to main site</a></i></span>
      </div>
    </div>
  </div>
</div>
<!-- <section
    *ngIf="!homeService.schoolData"
    style="
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      background-color: #808080;
    "
  >
    <ngx-spinner [fullScreen]="false" type="timer" size="medium" color="#FFFFFF">
      <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>
  </section> -->