<div class="container-fluid">
  <div class="row">
    <div class="col header">
      <div class="header-section">
        <h1 class="header-title">{{ managementData.full_name }}</h1>
      </div>
    </div>
  </div>
</div>
<div class="container" *ngIf="managementData">
  <div class="row">
    <div class="col-12 passport-section">
      <img
        src="{{ managementData.profile_photo }}"
        alt=""
        class="passport-photo"
        style="align-self: center"
      />
      <h3 class="remove-header-spaces" style="align-self: center">
        {{ managementData.full_name }}
      </h3>
      <h3
        class="faded-description remove-header-spaces"
        style="align-self: center"
      >
        <i>{{ managementData.title }}</i>
      </h3>
    </div>
    <div class="col-12" [innerHTML]="managementData.details"></div>
    <div class="col-12 no-data" *ngIf="!managementData.details">
      <h2><i>No record found!</i></h2>
    </div>
    <div class="col-12" style="display: flex; justify-content: center">
      <a
        routerLink="../home-iskcon"
        class="btn btn-outline-success btn-md"
        data-aos="zoom-out-up"
      >
        <i class="fas fa-arrow-left ml-1"></i>Back to temples page
      </a>
    </div>
  </div>
</div>
<div class="container" *ngIf="!managementData">
  <div class="row">
    <div class="col no-data">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
