import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-iskcon',
  templateUrl: './home-iskcon.component.html',
  styleUrls: ['./home-iskcon.component.scss'],
})
export class HomeIskconComponent implements OnInit {
  iskconData;
  pageSize = 12;

  managementList = [];
  managementData;
  mPage = 1;
  mFinished = false;
  mnotEmpty = true;
  mnotScrolly = true;

  newsList = [];
  newsData;
  nPage = 1;
  nFinished = false;
  nnotEmpty = true;
  nnotScrolly = true;

  galleryList = [];
  noData;
  page = 1;
  finished = false;
  notEmpty = true;
  notScrolly = true;
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getIskconData();
    this.getManagementList(this.pageSize, this.mPage);
    this.getNewsList(this.pageSize, this.nPage);
    this.getGalleryList(this.pageSize, this.page);
  }
  managementDetails(data) {
    this.router.navigateByUrl('/home/iskcon-management-details', {
      state: data,
    });
  }
  newsDetails(data) {
    this.router.navigateByUrl('/home/iskcon-news-details', {
      state: data,
    });
  }
  getIskconData() {
    this.http
      .get(environment.rootUrl + 'hktc-data/?title=iskcon')
      .subscribe((res: any) => {
        this.iskconData = res.results[0];
      });
  }
  getManagementList(pageSize, page) {
    return this.http
      .get(
        environment.rootUrl +
          'iskcon-management/?pageSize=' +
          pageSize +
          '&page=' +
          page
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.managementData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.mnotEmpty = false;
          } else {
            this.managementList = this.managementList.concat(newPost);
            this.mnotScrolly = true;
          }
        },
        (error) => {
          this.mnotEmpty = false;
          this.spinner.hide();
        }
      );
  }
  getNewsList(pageSize, page) {
    return this.http
      .get(
        environment.rootUrl +
          'iskcon-news/?pageSize=' +
          pageSize +
          '&page=' +
          page
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.newsData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.nnotEmpty = false;
          } else {
            this.newsList = this.newsList.concat(newPost);
            this.nnotScrolly = true;
          }
        },
        (error) => {
          this.nnotEmpty = false;
          this.spinner.hide();
        }
      );
  }
  getGalleryList(pageSize, page) {
    return this.http
      .get(
        environment.rootUrl +
          'iskcon-gallery/?pageSize=' +
          pageSize +
          '&page=' +
          page
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.noData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.notEmpty = false;
          } else {
            this.galleryList = this.galleryList.concat(newPost);
            this.notScrolly = true;
          }
        },
        (error) => {
          this.notEmpty = false;
          this.spinner.hide();
        }
      );
  }
  managementScroll() {
    this.mPage += 1;

    if (this.mnotScrolly && this.mnotEmpty) {
      this.spinner.show();
      this.mnotScrolly = false;
      this.getManagementList(this.pageSize, this.mPage);
    }
  }
  newsScroll() {
    this.nPage += 1;

    if (this.nnotScrolly && this.nnotEmpty) {
      this.spinner.show();
      this.nnotScrolly = false;
      this.getNewsList(this.pageSize, this.nPage);
    }
  }
  galleryScroll() {
    this.page += 1;

    if (this.notScrolly && this.notEmpty) {
      this.spinner.show();
      this.notScrolly = false;
      this.getGalleryList(this.pageSize, this.page);
    }
  }
}
