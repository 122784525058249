import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
//
export class LoginComponent implements OnInit {
  isLoading = false;
  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {}
  staffLoginForm = this.fb.group({
    email: [''],
    password: [''],
  });

  adminLogin() {
    this.isLoading = true;
    let body = {
      email: this.staffLoginForm.value.email,
      password: this.staffLoginForm.value.password,
    };
    this.http.post(environment.rootUrl + 'login/', body).subscribe(
      (res: any) => {
        this.isLoading = false;
        //let tokens = JSON.parse(res.tokens);
        localStorage.setItem('token', res.access);
        localStorage.setItem('refresh', res.refresh);
        this.router.navigateByUrl('/admin');
      },
      (err) => {
        this.isLoading = false;
        if (err.status == 400)
          this.toastr.error(
            'Incorrect username or password',
            'Authentication failed'
          );
        else this.toastr.error('Login request failed, please contact admin to check the issue !', 'Error');
      }
    );
  }
}
