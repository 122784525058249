<h2 mat-dialog-header>Update App Page Content</h2>
<form [formGroup]="homeService.appPageContentForm">
  <mat-dialog-content>
    <input type="hidden" formControlName="id" />
    <div class="form-group">
      <mat-form-field class="mat-input-fields">
        <input
          matInput
          formControlName="title"
          placeholder="Enter section title"
          required
        />
      </mat-form-field>
    </div>
    <div class="form-group">
      <label>Select image</label>
      <input
        type="file"
        (change)="handleFileInput($event)"
        id=""
        class="form-control"
      />
    </div>
    <!-- <a href="{{ prabhupadaService.prabhupadaImg }}"
      ><i>{{ prabhupadaService.prabhupadaImg }}</i></a
    > -->
    <div>
      <img *ngIf="displayFile" [src]="displayFile" class="display-image" />
      <img
        *ngIf="homeService.displayFile"
        [src]="homeService.displayFile"
        class="display-image"
      />
      <!-- <img
        *ngIf="prabhupadaService.prabhupadaImg"
        [src]="prabhupadaService.prabhupadaImg"
        class="display-image"
      /> -->
    </div>
    <div class="form-group">
      <mat-form-field class="mat-input-fields">
        <input
          matInput
          formControlName="rank"
          placeholder="Enter the rank"
          required
        />
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-raised-button
      mat-button
      color="primary"
      (click)="saveAppPageContent()"
    >
      Save app page content
    </button>
    <button mat-raised-button mat-button color="warn" mat-dialog-close>
      Close
    </button>
  </mat-dialog-actions>
</form>
