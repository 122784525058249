import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AppPageDialogComponent } from '../admin/app-page/app-page-dialog/app-page-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  homePageContent;
  slideshowList;
  videoUrl;
  mobilePageContent;
  displayFile;
  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {}
  homePageContentForm = this.fb.group({
    id: [''],
    title: [''],
    photo: [''],
    content: [''],
    rank: [''],
    video: [''],
  });
  appPageContentForm = this.fb.group({
    id: [''],
    title: [''],
    rank: [''],
  });
  slideshowForm = this.fb.group({
    id: [''],
    photo: [''],
    content: [''],
  });
  getHomePageContent() {
    this.http
      .get(environment.rootUrl + 'home-page-content/')
      .subscribe((res: any) => {
        this.homePageContent = res['results'];
      });
  }
  getMobileAppContent() {
    this.http
      .get(environment.rootUrl + 'app-page-content/')
      .subscribe((res: any) => {
        this.mobilePageContent = res['results'];
      });
  }
  getSlideshow() {
    this.http.get(environment.rootUrl + 'slideshow/').subscribe((res: any) => {
      this.slideshowList = res['results'];
      this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(
        res.results[1]['video']
      );
    });
  }
  editHomePageContent(data) {
    this.displayFile = data.photo;
    this.appPageContentForm.patchValue({
      id: data.id,
      title: data.title,
      rank: data.rank,
    });
    this.dialog.open(AppPageDialogComponent);
  }
}
