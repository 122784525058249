<div class="container-fluid login-section">
    <div class="row">
        <div class="col">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="login-content-section">
                            <div class="login-form-section">
                                <h1 class="login-header">Reset Password</h1>
                                <div class="form-section">
                                    <p class="form-text-header"><small>Please enter your new password</small></p>
                                    <div class="row">
                                        <form [formGroup]="resetPasswordForm">
                                        <div class="col-12">
                                            <mat-form-field class="mat-input-fields">
                                                <mat-label>New password</mat-label>
                                                <input type="password" matInput placeholder="New password" formControlName="password" (blur)="loginService.logValidationErrors(resetPasswordForm,formErrors,validationMessages)">
                                                <mat-icon matPrefix>lock</mat-icon>
                                            </mat-form-field>
                                            <span class="error-field" *ngIf="formErrors.password">
                                                {{ formErrors.password}}
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <mat-form-field class="mat-input-fields">
                                                <mat-label>Confirm password</mat-label>
                                                <input matInput type="password" placeholder="Confirm password" formControlName="confirmPassword" (blur)="loginService.logValidationErrors(resetPasswordForm,formErrors,validationMessages)">
                                                <mat-icon matPrefix>lock</mat-icon>
                                            </mat-form-field>
                                            <span class="error-field" *ngIf="formErrors.password">
                                                {{ formErrors.confirmPassword}}
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <button mat-raised-button color="primary" class="form-control login-btn" (click)=resetPassword()>
                                                <span>Reset password</span>
                                                <!-- <i *ngIf="isLoading">Please wait...</i> -->
                                            </button>
                                            <br>
                                            <br>
                                            <a routerLink="/">Back to Login page</a>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                            </div>
                            <div class="loading-content" *ngIf="isLoading">
                                <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



