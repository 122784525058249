import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AngularEpubViewerComponent } from 'angular-epub-viewer';

@Component({
  selector: 'app-read-book',
  templateUrl: './read-book.component.html',
  styleUrls: ['./read-book.component.scss'],
})
export class ReadBookComponent implements OnInit, AfterViewInit {
  bookData;
  @ViewChild('epubViewer')
  epubViewer: AngularEpubViewerComponent;
  constructor() {
    // let b = history.state;
    // this.epubViewer.openLink(this.bookData.book);
  }

  ngOnInit(): void {
    this.bookData = history.state;
  }
  ngAfterViewInit(): void {
    this.openBook();
  }
  openBook() {
    this.epubViewer.openLink(this.bookData.book);
  }
}
