import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DevoteeRegisterComponent } from './devotee-register/devotee-register.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { HomeComponent } from './home/home.component';
import { IndexComponent } from './home/index/index.component';
import { EventsComponent } from './home/events/events.component';
import { AboutComponent } from './home/about/about.component';
import { HomePrabhupadaComponent } from './home/home-prabhupada/home-prabhupada.component';
import { HomeGalleryComponent } from './home/home-gallery/home-gallery.component';
import { OutreachComponent } from './home/outreach/outreach.component';
import { NgModel } from '@angular/forms';
import { HomeSongsComponent } from './home/home-songs/home-songs.component';
import { HomeSongsListComponent } from './home/home-songs/home-songs-list/home-songs-list.component';
import { HomeBooksComponent } from './home/home-books/home-books.component';
import { FestivalsDetailsComponent } from './home/events/festivals-details/festivals-details.component';
import { EventsDetailsComponent } from './home/events/events-details/events-details.component';
import { TrainingCentresComponent } from './home/training-centres/training-centres.component';
import { TrainingCentreDetailsComponent } from './home/training-centres/training-centre-details/training-centre-details.component';
import { HomeTemplesComponent } from './home/home-temples/home-temples.component';
import { TemplesDetailsComponent } from './home/home-temples/temples-details/temples-details.component';
import { AboutTempleManagementComponent } from './home/home-temples/temples-details/about-temple-management/about-temple-management.component';
import { HomeIskconComponent } from './home/home-iskcon/home-iskcon.component';
import { IskconManagementDetailsComponent } from './home/home-iskcon/iskcon-management-details/iskcon-management-details.component';
import { IskconNewsDetailsComponent } from './home/home-iskcon/iskcon-news-details/iskcon-news-details.component';
import { ReadBookComponent } from './home/home-books/read-book/read-book.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    children: [
      { path: 'index', component: IndexComponent },
      { path: 'events', component: EventsComponent },
      {
        path: 'festival-details',
        component: FestivalsDetailsComponent,
      },
      { path: 'events-details', component: EventsDetailsComponent },
      { path: 'about', component: AboutComponent },
      {
        path: 'prabhupada',
        component: HomePrabhupadaComponent,
      },
      { path: 'outreach', component: OutreachComponent },
      { path: 'gallery', component: HomeGalleryComponent },
      { path: 'songs-categories', component: HomeSongsComponent },
      { path: 'songs-categories/:id', component: HomeSongsListComponent },
      { path: 'books', component: HomeBooksComponent },
      { path: 'read-book', component: ReadBookComponent },
      { path: 'training-centres', component: TrainingCentresComponent },
      {
        path: 'training-centres/:id',
        component: TrainingCentreDetailsComponent,
      },
      { path: 'temples', component: HomeTemplesComponent },
      { path: 'temples/:id', component: TemplesDetailsComponent },
      {
        path: 'temple-management-details',
        component: AboutTempleManagementComponent,
      },
      { path: 'home-iskcon', component: HomeIskconComponent },
      {
        path: 'iskcon-management-details',
        component: IskconManagementDetailsComponent,
      },
      { path: 'iskcon-news-details', component: IskconNewsDetailsComponent },
      { path: '', redirectTo: '/home/index', pathMatch: 'full' },
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'register', component: DevoteeRegisterComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((mod) => mod.AdminModule),
  },
  { path: '', redirectTo: '/home/index', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const routingComponents = [
  HomeComponent,
  IndexComponent,
  EventsComponent,
  FestivalsDetailsComponent,
  EventsDetailsComponent,
  AboutComponent,
  HomePrabhupadaComponent,
  OutreachComponent,
  HomeGalleryComponent,
  HomeSongsComponent,
  HomeSongsListComponent,
  HomeBooksComponent,
  TrainingCentresComponent,
  TrainingCentreDetailsComponent,
  HomeTemplesComponent,
  TemplesDetailsComponent,
  AboutTempleManagementComponent,
  HomeIskconComponent,
  IskconManagementDetailsComponent,
  IskconNewsDetailsComponent,

  LoginComponent,
  ForgotPasswordComponent,


  DevoteeRegisterComponent,
  PrivacyPolicyComponent,
];
