<div class="login-section-background">
  <div class="login-section">
    <div class="login-form-section">
      <div class="login-header">
        <h2>Admin Login</h2>
        <!-- {{ homeService.schoolData | json}} -->
        <img src="../../assets/images/logo.png" alt="Logo" class="logo" />
      </div>
      <form [formGroup]="staffLoginForm">
        <div class="form-group">
          <label for="inlineFormInputGroup">Email address</label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="fas fa-user"></i></div>
            </div>
            <input type="text" formControlName="email" class="form-control" placeholder="Email address" />
          </div>
        </div>
        <div class="form-group">
          <label for="inlineFormInputGroup">Password</label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-unlock-alt"></i>
              </div>
            </div>
            <input type="password" class="form-control" formControlName="password" placeholder="Password" />
          </div>
        </div>
        <div class="form-group">
          <button type="submit" class="form-control btn btn-success" (click)="adminLogin()">
            Login
          </button>
        </div>
        <div class="form-group">
          <a routerLink="/register"><button type="button" class="btn btn-outline-primary form-control">
              Register
            </button></a>
        </div>
      </form>
      <div class="loading-content" *ngIf="isLoading">
        <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
      </div>
      <div class="fgPart">
        <span><i><a routerLink="/forgot-password">Forgot password?</a></i></span>
        <span><i><a routerLink="/">Back to main site</a></i></span>
      </div>
      <div style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 20px;
        ">
        Our <a routerLink="/privacy-policy" target="_blank">privacy policy</a>
      </div>
    </div>
  </div>
</div>
<!-- <section
  *ngIf="!homeService.schoolData"
  style="
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: #808080;
  "
>
  <ngx-spinner [fullScreen]="false" type="timer" size="medium" color="#FFFFFF">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
</section> -->