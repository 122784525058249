<div class="container-fluid">
  <div class="row">
    <div class="col header">
      <div class="header-section">
        <h1 *ngIf="!songsList" class="header-title">Songs List</h1>
        <h1 class="header-title" *ngIf="songsList">
          {{ songsList[0]["category"]["category_name"] }}
        </h1>
      </div>
    </div>
  </div>
</div>
<div
  class="container"
  infiniteScroll
  [infiniteScrollDistance]="2"
  infiniteScrollThrottle="50"
  (scrolled)="onScroll()"
>
  <div class="row" *ngIf="songsList">
    <div class="col-12 remove-padding-images">
      <mat-accordion class="songs-accordion">
        <mat-expansion-panel *ngFor="let song of songsList">
          <mat-expansion-panel-header>
            <mat-panel-title
              ><h4 class="songs-title">
                {{ song.title }}(<i>{{ song.author }}</i
                >)
              </h4></mat-panel-title
            >
          </mat-expansion-panel-header>
          <div [innerHTML]="song.content"></div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="row" *ngIf="noData?.count == 0">
    <div class="col-12 no-data">
      <h2 class="no-data-txt">No record available</h2>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor=""
        size="default"
        color="#581f87"
        type="ball-pulse-sync"
        [fullScreen]="false"
      ></ngx-spinner>
    </div>
  </div>
</div>
<div class="container" *ngIf="!noData">
  <div class="row">
    <div class="col no-data">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
