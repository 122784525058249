
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageCropperModule } from 'ngx-image-cropper';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';

import { MaterialModule } from './material/material.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { RouterModule } from '@angular/router';
import { AngularEpubViewerModule } from 'angular-epub-viewer';
import { ReadBookComponent } from './home/home-books/read-book/read-book.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
    declarations: [AppComponent, routingComponents, ReadBookComponent, ForgotPasswordComponent, ResetPasswordComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        ReactiveFormsModule,
        LayoutModule,
        CKEditorModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        InfiniteScrollModule,
        NgxSpinnerModule,
        Ng2ImgMaxModule,
        MDBBootstrapModule.forRoot(),
        FlexLayoutModule,
        MatTabsModule,
        RouterModule,
        ImageCropperModule,
        AngularEpubViewerModule,
       // NgxMatSelectSearchModule
    ],
    providers: [
        // AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
