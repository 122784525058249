import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home-prabhupada',
  templateUrl: './home-prabhupada.component.html',
  styleUrls: ['./home-prabhupada.component.scss'],
})
export class HomePrabhupadaComponent implements OnInit {
  prabhupadaData;
  galleryList = [];
  noData;
  pageSize = 12;
  page = 1;
  finished = false;
  notEmpty = true;
  notScrolly = true;
  constructor(private http: HttpClient, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.getPrabhupada();
    this.getGalleryList(this.pageSize, this.page);
  }
  getPrabhupada() {
    this.http
      .get(environment.rootUrl + 'hktc-data/?title=prabhupada')
      .subscribe((res: any) => {
        this.prabhupadaData = res.results[0];
      });
  }
  getGalleryList(pageSize, page) {
    return this.http
      .get(
        environment.rootUrl +
          'prabhupada-gallery/?pageSize=' +
          pageSize +
          '&page=' +
          page
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.noData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.notEmpty = false;
          } else {
            this.galleryList = this.galleryList.concat(newPost);
            this.notScrolly = true;
          }
        },
        (error) => {
          this.notEmpty = false;
          this.spinner.hide();
        }
      );
  }
  onScroll() {
    this.page += 1;
    if (this.notScrolly && this.notEmpty) {
      this.spinner.show();
      this.notScrolly = false;
      this.getGalleryList(this.pageSize, this.page);
    }
  }
}
