import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TableData } from 'src/app/shared/table-data';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FestivalsService {
  festivalImg;
  eventImg;
  festivalDetails;
  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}
  festivalForm = this.fb.group({
    id: [''],
    name: [''],
    photo: [''],
    startDate: [''],
    endDate: [''],
    details: [''],
    period: [''],
  });
  eventsForm = this.fb.group({
    id: [''],
    name: [''],
    photo: [''],
    startDate: [''],
    endDate: [''],
    details: [''],
    location: [''],
  });
  //FIND FESTIVALS
  findFestival(
    search = '',
    sortOrder = 'start_date',
    pageNumber = 0,
    pageSize = 10
  ): Observable<TableData[]> {
    return this.http
      .get(environment.rootUrl + 'festivals', {
        params: new HttpParams()
          .set('search', search)
          .set('ordering', sortOrder)
          .set('page', pageNumber.toString())
          .set('pageSize', pageSize.toString()),
      })
      .pipe(map((res) => res['results']));
  }
  //FIND EVENTS
  findEvents(
    search = '',
    sortOrder = 'start_date',
    pageNumber = 0,
    pageSize = 10
  ): Observable<TableData[]> {
    return this.http
      .get(environment.rootUrl + 'events', {
        params: new HttpParams()
          .set('search', search)
          .set('ordering', sortOrder)
          .set('page', pageNumber.toString())
          .set('pageSize', pageSize.toString()),
      })
      .pipe(map((res) => res['results']));
  }
  //EDIT FESTIVAL
  editFestival(data) {
    this.festivalImg = data.photo;
    this.festivalForm.patchValue({
      id: data.id,
      name: data.name,
      startDate: data.start_date,
      endDate: data.end_date,
      details: data.details,
      period: data.period,
    });
    this.router.navigate(['/admin/add-festivals']);
  }
  //EDIT EVENT
  editEvent(data) {
    this.eventImg = data.event_photo;
    this.eventsForm.patchValue({
      id: data.id,
      name: data.event_name,
      startDate: data.start_date,
      endDate: data.end_date,
      details: data.details,
      location: data.location,
    });
    this.router.navigate(['/admin/add-events']);
  }
  //FESTIVALS DETAILS
  festivalsDetails(data) {
    this.festivalDetails = data;
    this.router.navigateByUrl('/home/festival-details', { state: data });
  }
  eventsDetails(data) {
    this.router.navigateByUrl('/home/events-details', { state: data });
  }
}
