<div class="container-fluid">
  <div class="row">
    <div class="col header">
      <div class="header-section">
        <h1 class="header-title">Books</h1>
      </div>
    </div>
  </div>
</div>
<div
  class="container"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>
  <div class="row">
    <!-- (click)="openBook(book)" -->
    <div
      class="col-lg-3 col-md-3 col-sm-4 col-6 navigation-card"
      *ngFor="let book of booksList"
      style="margin-bottom: 10px"
    >
      <mdb-card>
        <mdb-card-img src="{{ book.picture }}"></mdb-card-img>
        <!-- <mdb-card-body>
          <mdb-card-title
            ><h3>{{ book.title }}</h3></mdb-card-title
          >
        </mdb-card-body> -->
      </mdb-card>
    </div>
    <div class="col-12 no-data" *ngIf="noData?.count == 0">
      <h3 class="no-data-txt">No record found</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor=""
        size="default"
        color="#581f87"
        type="ball-pulse-sync"
        [fullScreen]="false"
      ></ngx-spinner>
    </div>
  </div>
</div>
<div *ngIf="!noData" class="container-fluid">
  <div class="row">
    <div class="col no-data">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
