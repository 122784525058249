import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iskcon-news-details',
  templateUrl: './iskcon-news-details.component.html',
  styleUrls: ['./iskcon-news-details.component.scss'],
})
export class IskconNewsDetailsComponent implements OnInit {
  newsData;
  constructor() {}

  ngOnInit(): void {
    this.newsData = history.state;
  }
}
