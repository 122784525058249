<!-- <header> -->
<!-- Navbar -->
<div class="container-fluid" *ngIf="homeService.homePageContent">
  <div class="row">
    <div class="col" style="padding: 0px">
      <div class="container-fluid hktc-header">
        <div class="row">
          <div class="col hktc-navigation">
            <div class="logo">
              <img src="../../assets/images/hktc.png" class="hktc-icon" />
            </div>
            <!--LARGE DEVICES NAVIGATION-->
            <nav class="nav-section">
              <i class="fa fa-times" aria-hidden="true" (click)="openNav()"></i>
              <a routerLink="/">Home</a>
              <a routerLink="about">About Us</a>
              <a routerLink="prabhupada">Prabhupada</a>
              <a routerLink="events">Events/Festivals</a>
              <a routerLink="gallery">Gallery</a>
              <a routerLink="songs-categories">Songs/Prayers</a>
              <a routerLink="books">Books</a>
              <a routerLink="training-centres">Training Centers</a>
              <a routerLink="temples">Temples</a>
              <a routerLink="home-iskcon">ISKCON</a>
              <a routerLink="/login">Login</a>
            </nav>
            <!--///LARGE DEVICES NAVIGATION-->
            <!-- SMALL DEVICES NAVIGATION-->
            <nav
              class="nav-section"
              [@openCloseMenu]="isOpen ? 'openMenu' : 'closeMenu'"
            >
              <i class="fa fa-times" aria-hidden="true" (click)="openNav()"></i>
              <a routerLink="/" (click)="openNav()">Home</a>
              <a routerLink="about" (click)="openNav()">About Us</a>
              <a routerLink="prabhupada" (click)="openNav()">Prabhupada</a>
              <a routerLink="events" (click)="openNav()">Events/Festivals</a>
              <a routerLink="gallery" (click)="openNav()">Gallery</a>
              <a routerLink="songs-categories" (click)="openNav()"
                >Songs/Prayers</a
              >
              <a routerLink="books" (click)="openNav()">Books</a>
              <a routerLink="training-centres" (click)="openNav()"
                >Training Centers</a
              >
              <a routerLink="temples" (click)="openNav()">Temples</a>
              <a routerLink="home-iskcon" (click)="openNav()">ISKCON</a>
              <a routerLink="/login">Login</a>
            </nav>
            <!--/// SMALL DEVICES NAVIGATION-->
            <i class="fa fa-bars" aria-hidden="true" (click)="openNav()"></i>
          </div>
        </div>
      </div>
      <!-- <main class="navbar-section">
        <mdb-navbar
          SideClass="navbar fixed-top navbar-expand-lg navbar-light scrolling-navbar"
        >
          <div class="container">

          Brand 
          <a class="navbar-brand" routerLink="/" target="_blank">
            <mdb-navbar-brand>
              <img src="../../assets/images/logo2.png" class="logo2" />
              <strong>Hare Krishna Training Center</strong>
            </mdb-navbar-brand>
          </a>
          <links>
            <ul class="navbar-nav ml-auto">
              <li class="nav-item active">
                <a class="nav-link" routerLink="/"
                  >Home
                  <span class="sr-only">(current)</span>
                </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link" routerLink="about">About Us </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link" routerLink="prabhupada">Prabhupada </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link" routerLink="gallery">Gallery</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" target="_blank">Songs/Prayers</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" target="_blank"
                  >Festivals/Events</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" target="_blank">Books</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" target="_blank">Centres</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" target="_blank">Temples</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" target="_blank">ISKCON</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/login" target="_blank"
                  >Login</a
                >
              </li>
            </ul>
          </links>
        </mdb-navbar>
      </main> -->
      <!-- Navbar -->
      <router-outlet></router-outlet>
      <!--Footer-->
      <footer class="page-footer text-center wow fadeIn" data-aos="fade-right">
        <!-- Social icons -->
        <div style="background-color: #2c3e50">
          <div class="container">
            <div class="row">
              <div class="col-sm-6">
                <h1 class="h1 mt-2">Contact us:</h1>
                <table style="text-align: left; margin: auto">
                  <tr>
                    <td><i class="fa fa-phone" aria-hidden="true"></i></td>
                    <td>:</td>
                    <td>+254 725841058</td>
                  </tr>
                  <tr>
                    <td><i class="fa fa-envelope" aria-hidden="true"></i></td>
                    <td>:</td>
                    <td>simpleliving1896@gmail.com</td>
                  </tr>
                </table>
              </div>
              <div class="col-sm-6">
                <h1 class="h1 mt-2">Connect with us:</h1>
                <a href="https://www.facebook.com/hkytc.co.ke" target="_blank"
                  ><i class="fab fa-facebook-square"></i
                ></a>
                <a href="https://www.facebook.com/hkytc.co.ke" target="_blank"
                  ><i class="fab fa-twitter-square"></i
                ></a>
                <a
                  href="https://www.instagram.com/hare_krishna_training_centre"
                  target="_blank"
                  ><i class="fab fa-instagram-square"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
        <!-- Social icons -->

        <!--Copyright-->
        <div class="footer-copyright" style="background-color: #000000">
          © 2021 Web developed by:
          <a href="https://www.muabatech.com" target="_blank"> Muabatech</a>
        </div>
        <!--/.Copyright-->
      </footer>
      <!--/.Footer-->
    </div>
  </div>
</div>
<div class="container-fluid" *ngIf="!homeService.homePageContent">
  <div class="row">
    <div class="col" style="padding: 0px; margin: 0px">
      <div
        class="row home-background-loading"
        style="padding: 0px; margin: 0px"
      >
        <div class="col home-loading-content" style="padding: 0px; margin: 0px">
          <ngx-spinner
            [fullScreen]="false"
            type="timer"
            size="medium"
            color="#FFFFFF"
          >
            <p style="font-size: 20px; color: white">Loading...</p>
          </ngx-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
