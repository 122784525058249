import { Component, OnInit } from '@angular/core';
import { PrivacyService } from './privacy.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  privacyData;
  constructor(public privacyService: PrivacyService) {}

  ngOnInit(): void {
    this.privacyService.getData('policy').subscribe((res: any) => {
      this.privacyData = res.results[0]['details'];
    });
  }
}
