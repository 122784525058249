import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppUserAuth } from '../auth/app-user-auth';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  securityObject : AppUserAuth = new AppUserAuth();
  authObject;
  isLoading = false;
  jwtHelperService = new JwtHelperService()

  constructor(private http : HttpClient,private router : Router,private toastr : ToastrService) { }
  login(userObj){
    this.isLoading = true;
    this.http.post(environment.rootUrl+"login/",userObj).subscribe((res:any)=>{
      localStorage.setItem("token",res.access)
      localStorage.setItem("refresh",res.refresh)
      // localStorage.setItem("expiration",decryptedToken.exp)
      //localStorage.setItem("email",res.email)
      //localStorage.setItem("fullName",res.fullName)
      //localStorage.setItem("user",res.user)
      Object.assign(this.securityObject,{"user":res.user,"bearerToken":res.access,"refreshToken":res.refresh})
      this.isLoading = false;
      this.router.navigateByUrl('dashboard')
    },
    (error:any)=>{
      this.isLoading = false;
      this.toastr.error(error.error.detail,"Error")
      // this.errorResponse = error.error
      // if(error.error.detail)
      // document.getElementById("student-error-login-section").innerText = error.error.detail;
    })
  }
  loginObject(){
    let token:any = localStorage.getItem('token')
    let decryptedToken = this.jwtHelperService.decodeToken(token)
    return {
      expiration:decryptedToken.exp,
      user:decryptedToken.user_details
    }
  }
  resetSecurityObject():void{
    this.securityObject.user = {};
    localStorage.removeItem("token")
    localStorage.removeItem("refresh")
    localStorage.removeItem("expiration")
    localStorage.removeItem("name")
    localStorage.removeItem("email")
    localStorage.removeItem("role")
    localStorage.removeItem("user")
  }
    //FORM VALIDATION
    logValidationErrors(group:FormGroup,formErrors,validationMessages):void{
      Object.keys(group.controls).forEach((key:string)=>{
        const abstractControl = group.get(key);
        if(abstractControl instanceof FormGroup){
          this.logValidationErrors(abstractControl,formErrors,validationMessages);
        }else{
          formErrors[key] = '';
          if(abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)){
            const messages = validationMessages[key];
              for (const errorKey in abstractControl.errors){
                  if(errorKey){
                    formErrors[key] += messages[errorKey]+' ';
                  }
              }
          }
  
        }
      })
    }
    //MUST MATCH
    mustMatch(controlName:string,matchingControlName:string){
      return(formGroup:FormGroup)=>{
        const control =  formGroup.controls[controlName]
        const matchingControl = formGroup.controls[matchingControlName]
        if(matchingControl.errors && !matchingControl.errors['mustMatch']){
          return
        }
        if(control.value !== matchingControl.value){
          matchingControl.setErrors({mustMatch:true})
        }else{
          matchingControl.setErrors(null)
        }
      }
    }
   
    //HAS ROLE
    hasRole(admin){
      let auth = this.loginObject()
      if(auth.user.isStaff == admin){
        return true;
      }else{
        return false
      }
    }
    //IS CLAIM VALID
    private isClaimValid(claimType:string):boolean{
      let ret: boolean = false
      let claimValue: string = ''
      let auth = ['admin']
      if(auth.indexOf("claimType") !== -1){
        ret = true
      }else{
        ret = false
      }
      return ret
    }
    //IS CLAIM VALID
    public hasClaim(claimType:any):boolean{
      let ret: boolean = false
      let loginObject = this.loginObject()
      if (loginObject.user.isSuperUser){
        ret = true
      }else{
        for(let x of claimType){
          console.log(x)
          for(let y of loginObject.user.permissions){
            console.log(y)
            if(x == y){
              ret = true
              break
            }
          }
          if(ret == true){
            break;
          }
        }
      }
      console.log(ret)
      // let claims: string[] = claimType
      // if (claims){
      //   for(let index=0; index < claims.length; index++){
      //     if(this.isClaimValid(claims[index])){
      //       ret = true
      //       break;
      //     }
      //   }
      // }
      return ret
    }
}
