<div class="container-fluid">
  <div class="row">
    <div class="col header">
      <div class="header-section">
        <h1 class="header-title">Hare Krishna Training Centres</h1>
      </div>
    </div>
  </div>
</div>
<div
  class="container-fluid"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
  *ngIf="hktcList?.length"
>
  <div class="row" *ngIf="hktcList">
    <div
      class="col-lg-3 col-md-4 col-sm-6 remove-padding-images"
      *ngFor="let record of hktcList"
      data-aos="flip-down"
    >
      <a [routerLink]="['../training-centres', record.id]">
        <mdb-card>
          <mdb-card-img src="{{ record.profile_photo }}"></mdb-card-img>
          <mdb-card-body>
            <mdb-card-title>
              <h3 style="margin: 0px">
                {{ record.name }} (<i>{{ record.temple.temple_name }}</i
                >)
              </h3>
              <h4 style="color: #808080; margin: 0px">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                {{ record.locality }} (<i
                  >{{ record.temple.city }}, {{ record.temple.country }}</i
                >)
              </h4>
              <button class="btn btn-outline-success btn-md">
                View more
                <i class="fas fa-arrow-right ml-1"></i>
              </button>
            </mdb-card-title>
          </mdb-card-body>
        </mdb-card>
      </a>
    </div>
  </div>
  <div class="row" *ngIf="noData?.count == 0">
    <div class="col no-data">
      <h6><i>No record found!</i></h6>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor=""
        size="default"
        color="#581f87"
        type="ball-pulse-sync"
        [fullScreen]="false"
      ></ngx-spinner>
    </div>
  </div>
</div>
<div class="container" *ngIf="!hktcList.length">
  <div class="row">
    <div class="col no-data">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
