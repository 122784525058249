import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TableData } from 'src/app/shared/table-data';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TemplesService {
  individualTemple;
  individualHktc;
  templesList;
  hktcList;
  templeImg;
  templeManagementImg;
  templePropertyImg;
  trainingCenterImg;
  templeGalleryImg;
  hktcGalleryImg;
  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private route: Router
  ) {}
  templesForm = this.fb.group({
    id: [''],
    country: [''],
    city: [''],
    locality: [''],
    name: [''],
    photo: [''],
    details: [''],
  });
  //TEMPLE MANAGEMENT
  templeManagementForm = this.fb.group({
    id: [''],
    temple: [''],
    fullName: [''],
    title: [''],
    details: [''],
    rank: [''],
    photo: [''],
  });
  //TEMPLE PROPERTY FORM
  templePropertyForm = this.fb.group({
    id: [''],
    temple: [''],
    name: [''],
    photo: [''],
    details: [''],
  });
  //TEMPLES GALLERY FORM
  templesGalleryForm = this.fb.group({
    id: [''],
    temple: [''],
    photo: [''],
    description: [''],
  });
  //HKTC FORM
  hktcForm = this.fb.group({
    id: [''],
    temple: [''],
    name: [''],
    photo: [''],
    locality: [''],
    details: [''],
  });
  //STUDENTS FORM
  studentsForm = this.fb.group({
    id: [''],
    fullName: [''],
    hktc: [''],
    institution: [''],
    phone: [''],
    email: [''],
    gender: [''],
  });
  //HKTC GALLERY
  hktcGalleryForm = this.fb.group({
    id: [''],
    hktc: [''],
    photo: [''],
    description: [''],
  });
  //TEMPLES
  findTemples(
    search = '',
    sortOrder = 'country',
    pageNumber = 0,
    pageSize = 10
  ): Observable<TableData[]> {
    return this.http
      .get(environment.rootUrl + 'temples', {
        params: new HttpParams()
          .set('search', search)
          .set('ordering', sortOrder)
          .set('page', pageNumber.toString())
          .set('pageSize', pageSize.toString()),
      })
      .pipe(map((res) => res['results']));
  }
  //TEMPLE MANAGEMENT
  findTempleManagement(
    search = '',
    sortOrder = 'rank',
    pageNumber = 0,
    pageSize = 10,
    templeId
  ): Observable<TableData[]> {
    return this.http
      .get(environment.rootUrl + 'temple-management', {
        params: new HttpParams()
          .set('search', search)
          .set('ordering', sortOrder)
          .set('page', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('temple__id', templeId),
      })
      .pipe(map((res) => res['results']));
  }
  //TEMPLE PROPERTIES
  findTempleProperties(
    search = '',
    sortOrder = 'created_date',
    pageNumber = 0,
    pageSize = 10,
    templeId
  ): Observable<TableData[]> {
    return this.http
      .get(environment.rootUrl + 'temple-properties', {
        params: new HttpParams()
          .set('search', search)
          .set('ordering', sortOrder)
          .set('page', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('temple__id', templeId),
      })
      .pipe(map((res) => res['results']));
  }
  //TEMPLE HKTC
  findHktc(
    search = '',
    sortOrder = 'created_date',
    pageNumber = 0,
    pageSize = 10,
    templeId
  ): Observable<TableData[]> {
    return this.http
      .get(environment.rootUrl + 'hktc', {
        params: new HttpParams()
          .set('search', search)
          .set('ordering', sortOrder)
          .set('page', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('temple__id', templeId),
      })
      .pipe(map((res) => res['results']));
  }
  //FIND DEVOTEE
  findDevotee(
    search = '',
    sortOrder = 'created_date',
    pageNumber = 0,
    pageSize = 10,
    hktcId
  ): Observable<TableData[]> {
    return this.http
      .get(environment.rootUrl + 'users', {
        params: new HttpParams()
          .set('search', search)
          .set('ordering', sortOrder)
          .set('page', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('hktc__id', hktcId),
      })
      .pipe(map((res) => res['results']));
  }
  //FIND STUDENTS
  findStudents(
    search = '',
    sortOrder = 'created_date',
    pageNumber = 0,
    pageSize = 10,
    hktcId
  ): Observable<TableData[]> {
    return this.http
      .get(environment.rootUrl + 'students', {
        params: new HttpParams()
          .set('search', search)
          .set('ordering', sortOrder)
          .set('page', pageNumber.toString())
          .set('pageSize', pageSize.toString())
          .set('hktc__id', hktcId),
      })
      .pipe(map((res) => res['results']));
  }
  //GET TEMPLE INDIVIDUAL TEMPLES
  getIndividualTemple(id) {
    this.http
      .get(environment.rootUrl + 'temples/' + id + '/')
      .subscribe((res) => {
        this.individualTemple = res;
      });
  }
  //GET INDIVIDUAL HKTC
  getIndividualHktc(id) {
    this.http.get(environment.rootUrl + 'hktc/' + id + '/').subscribe((res) => {
      this.individualHktc = res;
    });
  }
  getTemples() {
    this.http.get(environment.rootUrl + 'temples/').subscribe((res) => {
      this.templesList = res['results'];
    });
  }
  getHktc() {
    this.http
      .get(environment.rootUrl + 'hktc/?pageSize=500')
      .subscribe((res) => {
        this.hktcList = res['results'];
      });
  }
  //EDIT TEMPLE
  editTemple(data) {
    this.templeImg = data.profile_photo;
    this.templesForm.patchValue({
      id: data.id,
      country: data.country,
      city: data.city,
      locality: data.locality,
      name: data.temple_name,
      details: data.details,
    });
    this.route.navigate(['/admin/add-temples']);
  }
  //EDIT TEMPLE MANAGEMENT
  editTempleManagement(data) {
    this.templeManagementImg = data.profile_photo;
    this.templeManagementForm.patchValue({
      id: data.id,
      temple: data.temple.id,
      fullName: data.full_name,
      title: data.title,
      details: data.details,
      rank: data.rank,
    });
    this.route.navigate(['/admin/add-temple-management']);
  }
  //EDIT TEMPLE PROPERTIES
  editTempleProperty(data) {
    this.templePropertyImg = data.property_photo;
    this.templePropertyForm.patchValue({
      id: data.id,
      temple: data.temple.id,
      name: data.property_name,
      details: data.details,
    });
    this.route.navigate(['/admin/add-temple-properties']);
  }
  //EDIT TRAINING CENTRE
  editTrainingCenter(data) {
    this.trainingCenterImg = data.profile_photo;
    this.hktcForm.patchValue({
      id: data.id,
      temple: data.temple.id,
      name: data.name,
      locality: data.locality,
      details: data.details,
    });
    this.route.navigate(['/admin/add-hktc']);
  }
  //GET HKTC GALLERY
}
