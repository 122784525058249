import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-temples-details',
  templateUrl: './temples-details.component.html',
  styleUrls: ['./temples-details.component.scss'],
})
export class TemplesDetailsComponent implements OnInit {
  sub: Subscription;
  globalId;
  templeData;
  galleryList = [];
  noData;
  pageSize = 12;
  page = 1;
  finished = false;
  notEmpty = true;
  notScrolly = true;

  managementList = [];
  managementData;
  mPageSize = 12;
  mPage = 1;
  mFinished = false;
  mnotEmpty = true;
  mnotScrolly = true;

  propertyList = [];
  propertyData;
  pPageSize = 12;
  pPage = 1;
  pFinished = false;
  pnotEmpty = true;
  pnotScrolly = true;

  hktcList = [];
  hktcData;
  cPage = 1;
  cFinished = false;
  cnotEmpty = true;
  cnotScrolly = true;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sub = this.route.paramMap.subscribe((params) => {
      let id = params.get('id');
      this.globalId = id;
      this.getTemple(id);
      this.getManagementList(this.mPageSize, this.mPage, id);
      this.getPropertyList(this.pageSize, this.pPage, id);
      this.getHktcList(this.pageSize, this.cPage, id);
      this.getGalleryList(this.pageSize, this.page, id);
    });
  }
  getTemple(id) {
    return this.http
      .get(environment.rootUrl + 'temples/' + id + '/')
      .subscribe((res: any) => {
        this.templeData = res;
      });
  }
  managementDetails(data) {
    this.router.navigateByUrl('/home/temple-management-details', {
      state: data,
    });
  }
  getGalleryList(pageSize, page, id) {
    return this.http
      .get(
        environment.rootUrl +
          'temple-gallery/?pageSize=' +
          pageSize +
          '&page=' +
          page +
          '&temple__id=' +
          id
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.noData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.notEmpty = false;
          } else {
            this.galleryList = this.galleryList.concat(newPost);
            this.notScrolly = true;
          }
        },
        (error) => {
          this.notEmpty = false;
          this.spinner.hide();
        }
      );
  }
  getManagementList(pageSize, page, id) {
    return this.http
      .get(
        environment.rootUrl +
          'temple-management/?pageSize=' +
          pageSize +
          '&page=' +
          page +
          '&temple__id=' +
          id
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.managementData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.mnotEmpty = false;
          } else {
            this.managementList = this.managementList.concat(newPost);
            this.mnotScrolly = true;
          }
        },
        (error) => {
          this.mnotEmpty = false;
          this.spinner.hide();
        }
      );
  }
  getPropertyList(pageSize, page, id) {
    return this.http
      .get(
        environment.rootUrl +
          'temple-properties/?pageSize=' +
          pageSize +
          '&page=' +
          page +
          '&temple__id=' +
          id
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.propertyData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.pnotEmpty = false;
          } else {
            this.propertyList = this.propertyList.concat(newPost);
            this.pnotScrolly = true;
          }
        },
        (error) => {
          this.pnotEmpty = false;
          this.spinner.hide();
        }
      );
  }
  getHktcList(pageSize, page, id) {
    return this.http
      .get(
        environment.rootUrl +
          'hktc/?pageSize=' +
          pageSize +
          '&page=' +
          page +
          '&temple__id=' +
          id
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.hktcData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.cnotEmpty = false;
          } else {
            this.hktcList = this.hktcList.concat(newPost);
            this.cnotScrolly = true;
          }
        },
        (error) => {
          this.cnotEmpty = false;
          this.spinner.hide();
        }
      );
  }
  onScroll() {
    this.page += 1;

    if (this.notScrolly && this.notEmpty) {
      this.spinner.show();
      this.notScrolly = false;
      this.getGalleryList(this.pageSize, this.page, this.globalId);
    }
  }
  managementScroll() {
    this.mPage += 1;

    if (this.mnotScrolly && this.mnotEmpty) {
      this.spinner.show();
      this.mnotScrolly = false;
      this.getManagementList(this.mPageSize, this.mPage, this.globalId);
    }
  }

  propertyScroll() {
    this.pPage += 1;

    if (this.pnotScrolly && this.pnotEmpty) {
      this.spinner.show();
      this.pnotScrolly = false;
      this.getPropertyList(this.pageSize, this.pPage, this.globalId);
    }
  }
  hktcScroll() {
    this.cPage += 1;

    if (this.cnotScrolly && this.cnotEmpty) {
      this.spinner.show();
      this.cnotScrolly = false;
      this.getHktcList(this.pageSize, this.cPage, this.globalId);
    }
  }
}
