<div class="container-fluid remove-container-padding">
  <div class="row">
    <div class="col header remove-container-padding">
      <div class="header-section">
        <h1 class="header-title">A.C.Bhaktivedanta Swami Prabhupada</h1>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col remove-container-padding">
      <div class="container-fluid remove-container-padding">
        <div class="row">
          <div class="col remove-container-padding">
            <mat-tab-group>
              <mat-tab label="Biography"
                ><ng-template matTabContent>
                  <div class="container-fluid remove-container-padding">
                    <div class="row">
                      <div class="col-12 remove-container-padding">
                        <img
                          src="{{ prabhupadaData.photo }}"
                          alt=""
                          class="img-fluid banner-image"
                          *ngIf="prabhupadaData.photo"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="container-fluid">
                    <!-- <div class="container"> -->
                    <div class="row" *ngIf="prabhupadaData">
                      <div class="col-12" style="padding: 0px"></div>
                      <div
                        class="col-12"
                        [innerHtml]="prabhupadaData.details"
                        *ngIf="prabhupadaData.details"
                      ></div>
                      <div
                        class="col-12 no-data"
                        *ngIf="!prabhupadaData?.details"
                      >
                        <h2 class="no-data-txt">No record available!</h2>
                      </div>
                    </div>
                    <div class="row" *ngIf="!prabhupadaData">
                      <div class="col-12 no-data">
                        <mat-spinner></mat-spinner>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </ng-template></mat-tab
              >
              <mat-tab label="Gallery">
                <ng-template matTabContent>
                  <div
                    class="container-fluid remove-container-padding"
                    infiniteScroll
                    [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="50"
                    (scrolled)="onScroll()"
                    *ngIf="galleryList?.length"
                  >
                    <div class="row" *ngIf="galleryList">
                      <div
                        class="col-lg-3 col-md-4 col-sm-6 remove-padding-images"
                        *ngFor="let record of galleryList"
                      >
                        <mdb-card>
                          <mdb-card-img src="{{ record.photo }}"></mdb-card-img>
                          <mdb-card-body>
                            <mdb-card-title>
                              <h3 style="margin: 0px" *ngIf="record.details">
                                {{ record.description }}
                              </h3>
                              <h4 style="color: #808080; margin: 0px">
                                {{ record.created_date | date: "full" }}
                              </h4>
                            </mdb-card-title>
                          </mdb-card-body>
                        </mdb-card>
                      </div>
                    </div>
                    <div class="row" *ngIf="noData?.count == 0">
                      <div class="col no-data">
                        <h6><i>No record found!</i></h6>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <ngx-spinner
                          bdOpacity="0.9"
                          bdColor=""
                          size="default"
                          color="#581f87"
                          type="ball-pulse-sync"
                          [fullScreen]="false"
                        ></ngx-spinner>
                      </div>
                    </div>
                  </div>
                  <div class="container" *ngIf="!galleryList.length">
                    <div class="row">
                      <div class="col no-data">
                        <mat-spinner></mat-spinner>
                      </div>
                    </div>
                  </div> </ng-template
              ></mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
