import { Component, OnInit } from '@angular/core';
import { FestivalsService } from '../festivals.service';

@Component({
  selector: 'app-festivals-details',
  templateUrl: './festivals-details.component.html',
  styleUrls: ['./festivals-details.component.scss'],
})
export class FestivalsDetailsComponent implements OnInit {
  festivalData;
  constructor(public festivalService: FestivalsService) {}

  ngOnInit(): void {
    this.festivalData = history.state;
  }
}
