<!--Carousel Wrapper-->
<!-- <main class="carousel-section">
  <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
    <mdb-carousel-item>
      <div class="view w-100">
        <img
          src="../../assets/images/slides/slide1.jpeg"
          alt="Slide show"
          class="d-block w-100"
          data-aos="zoom-in"
        />
        <div class="mask waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <div class="animated fadeInDown delay-10s">
          <h3 class="h3-responsive carousel-txt">
            Srila Prabhupada (<i>Los Angeles, December 13, 1968</i>)
          </h3>
          <p class="carousel-txt">
            So this renunciation is recommended. If we simply remain in material
            opulence and enjoyment, that will be our disqualification for
            entering into the kingdon of God. Too much attachment, too much
            increasing of material civilization means that next life is very
            much dark
          </p>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img
          src="../../assets/images/slides/slide2.jpeg"
          alt="Slide show"
          class="d-block w-100"
        />
        <div class="mask waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <div class="animated fadeInDown delay-10s">
          <h3 class="h3-responsive carousel-txt">
            Srila Prabhupada (<i>May 01, 1968</i>)
          </h3>
          <p class="carousel-txt">
            Human life is especially meant for spiritual education or Krishna
            Conscious education. For eating, sleeping, sex life and defence it
            is not very much necessary for the human being to receive education,
            because naturally every living being has natural education for these
            demands of the body. Therefore for the human being spiritual
            education is essentiall.
          </p>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img
          src="../../assets/images/slides/slide3.jpeg"
          alt="Slide show"
          class="d-block w-100"
        />
        <div class="mask waves-light" mdbWavesEffect></div>
        <div class="carousel-caption">
          <div class="animated fadeInDown delay-10s">
            <h3 class="h3-responsive carousel-txt">
              Srila Prabhupada (<i>Hawaii, January 20, 1974</i>)
            </h3>
            <p class="carousel-txt">
              It doesn't matter how we have learned to love God, either
              Christian religion or Hindu religion or Muhammadan, but if you
              have got that success, that you have learned how to love God, then
              your life is successful.
            </p>
          </div>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img
          src="../../assets/images/slides/slide4.jpeg"
          alt="Slide show"
          class="d-block w-100"
        />
        <div class="mask waves-light" mdbWavesEffect></div>
        <div class="carousel-caption">
          <div class="animated fadeInDown delay-10s">
            <h3 class="h3-responsive carousel-txt">
              <i>Bhagavad-Gita 6.6</i>
            </h3>
            <p class="carousel-txt">
              For him who has conquered the mind, the mind is the best of
              friends; but for one who has failed to do so, his mind will remain
              the greatest enemy.
            </p>
          </div>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img
          src="../../assets/images/slides/slide5.jpeg"
          alt="Slide show"
          class="d-block w-100"
        />
        <div class="mask waves-light" mdbWavesEffect></div>
        <div class="carousel-caption">
          <div class="animated fadeInDown delay-10s">
            <h3 class="h3-responsive carousel-txt">
              <i>Bhagavad-Gita 3.21</i>
            </h3>
            <p class="carousel-txt">
              Whatever action a great man perfoms, common men follow. And
              whatever standards he sets by exemplary acts all the world
              pursues.
            </p>
          </div>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img
          src="../../assets/images/slides/slide6.jpeg"
          alt="Slide show"
          class="d-block w-100"
        />
        <div class="mask waves-light" mdbWavesEffect></div>
        <div class="carousel-caption">
          <div class="animated fadeInDown delay-10s">
            <h3 class="h3-responsive carousel-txt">
              <i>Srimad Bhagavatam 8.16.19</i>
            </h3>
            <p class="carousel-txt">
              Unfortunately, this spiritual education is completely absent from
              modern human civilization. No one understands his real
              self-interest, which lies with the spirit, soul, not with the
              material body. Education means spiritual education. To work hard
              in the bodily conception of life, withoud spiritual education, is
              to live like an animal.
            </p>
          </div>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img
          src="../../assets/images/slides/slide7.jpeg"
          alt="Slide show"
          class="d-block w-100"
        />
        <div class="mask waves-light" mdbWavesEffect></div>
        <div class="carousel-caption">
          <div class="animated fadeInDown delay-10s">
            <h3 class="h3-responsive carousel-txt">
              Srila Prabhupada (<i>New Delhi, November 30, 1975</i>)
            </h3>
            <p class="carousel-txt">
              Instead of spending money to advance the cause of Krishna
              consiousness, one spends his hard earned money on clubs, brothels,
              liquor, drugs, cigarettes, slaughterhouses and so forth. All these
              activities are sinful, and because we are engaged in such sinful
              activities constantly we are constantly reaping the result of our
              sinful work, which is suffering in one way or another.
            </p>
          </div>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img
          src="../../assets/images/slides/slide8.jpeg"
          alt="Slide show"
          class="d-block w-100"
        />
        <div class="mask waves-light" mdbWavesEffect></div>
        <div class="carousel-caption">
          <div class="animated fadeInDown delay-10s">
            <h3 class="h3-responsive carousel-txt">
              Srila Prabhupada (<i>December 17, 1967</i>)
            </h3>
            <p class="carousel-txt">
              Without education nobody has any social position and all our
              students in Krishna Consciousness are expected to be preachers. So
              preachers must have sufficient education because they have to meet
              with so many opposing elements. Education should be continued at
              the same time chanting should be continued. There will be no
              difficulty.
            </p>
          </div>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img
          src="../../assets/images/slides/slide9.jpeg"
          alt="Slide show"
          class="d-block w-100"
        />
        <div class="mask waves-light" mdbWavesEffect></div>
        <div class="carousel-caption">
          <div class="animated fadeInDown delay-10s">
            <h3 class="h3-responsive carousel-txt">
              <i>Srimad Bhagavatam 1.19.13</i>
            </h3>
            <p class="carousel-txt">
              This beautiful material world is nothing but a shadowy reflection
              of the reality, the Kingdom of God.
            </p>
          </div>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img
          src="../../assets/images/slides/slide10.jpeg"
          alt="Slide show"
          class="d-block w-100"
        />
        <div class="mask waves-light" mdbWavesEffect></div>
        <div class="carousel-caption">
          <div class="animated fadeInDown delay-10s">
            <h3 class="h3-responsive carousel-txt">
              Srila Prabhupada (<i>March 03, 1975</i>)
            </h3>
            <p class="carousel-txt">
              This education institution, must be unique in the world, because
              nobody is interested to give their children spiritual education.
            </p>
          </div>
        </div>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img
          src="../../assets/images/slides/slide11.jpeg"
          alt="Slide show"
          class="d-block w-100"
        />
        <div class="mask waves-light" mdbWavesEffect></div>
        <div class="carousel-caption">
          <div class="animated fadeInDown delay-10s">
            <h3 class="h3-responsive carousel-txt">
              Srila Prabhupada (<i
                >Letter to: Cyavana Bombay, December 29, 1972</i
              >)
            </h3>
            <p class="carousel-txt">
              So keep the Nairobi center always very active, nicely decorated,
              worship the deities just to the highest standard, making sure that
              all of the students are rising to attend teh mangala aratrika,
              chanting regularly sixteen rounds, reading books - in this way
              utilize what Krishna has given us there and develop it for the
              headquarters building for Africa.
            </p>
          </div>
        </div>
      </div>
    </mdb-carousel-item>
  </mdb-carousel>
</main> -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 banner-section" style="padding: 0px; margin: 0px">
      <div class="banner-content">
        <h1 class="banner-title">
          {{ homeService.homePageContent[0]["title"] }}
        </h1>
        <h2 class="banner-title-2">
          {{ homeService.homePageContent[0]["details"] }}
        </h2>
        <img src="../../assets/images/down-arrow.gif" class="down-arrow-gif" />
      </div>
    </div>
    <div class="video-banner">
      <div class="video-section">
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/qe0FSkmzAcw"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <!-- <video
          width="100%"
          controls
          autoplay
          class="video"
          *ngIf="homeService.homePageContent[1]['file']"
        >
          <source
            src="{{ homeService.homePageContent[1]['file'] }}"
            type="video/mp4"
          />
          The video format is not supported!
        </video>
        <div
          class="youtube-video"
          [innerHTML]="homeService.homePageContent[1]['video']"
          *ngIf="!homeService.homePageContent[1]['file']"
        ></div> -->
        <!-- <iframe
          width="100%"
          [src]="videoUrl"
          frameborder="0"
          *ngIf="!homeService.homePageContent[1]['file']"
        ></iframe>-->
        <!-- <h2 class="video-h2-txt">
          {{ homeService.homePageContent[1]["details"] }}
        </h2> -->
        <!-- <button
          mdbBtn
          type="button"
          color="success"
          outline="true"
          mdbWavesEffect
        >
          {{ homeService.homePageContent[1]["title"] }}
          <i class="fas fa-arrow-right ml-1"></i>
        </button> -->
        <!-- <a
          routerLink="/home/about"
          class="btn btn-outline-success btn-md"
          data-aos="zoom-out-up"
          >{{ homeService.homePageContent[1]["title"] }}
          <i class="fas fa-arrow-right ml-1"></i>
        </a> -->
      </div>
    </div>
  </div>
</div>
<!--/.Carousel Wrapper-->

<!--ABOUT US WRAPPER-->
<main>
  <div class="container">
    <section data-aos="fade-up">
      <div class="row">
        <!--Grid column-->
        <div class="col-md-6 mb-4">
          <h1
            class="h2 text-center mb-2"
            data-aos="fade-down"
            style="color: #22313f; font-weight: bolder"
          >
            {{ homeService.homePageContent[2]["title"] }}
          </h1>
          <img
            src="{{ homeService.homePageContent[2]['file'] }}"
            class="img-fluid z-depth-1-half"
            alt=""
          />
        </div>
        <!--Grid column-->
        <div class="col-md-6 mb-4">
          <!-- Main heading -->
          <h3 class="h3 mt-2" style="color: #22313f">
            {{ homeService.homePageContent[2]["details"] }}
          </h3>
          <!-- <h3 class="h3" style="color: #22313f; font-weight: bolder">
            Srila Prabhupada <i>(March 03, 1975)</i>
          </h3> -->
          <!-- CTA -->
          <a
            routerLink="/home/about"
            class="btn btn-outline-success btn-md"
            data-aos="zoom-out-up"
            >Find out more
            <i class="fas fa-arrow-right ml-1"></i>
          </a>
        </div>
      </div>
    </section>
  </div>
</main>
<!--ABOUT US WRAPPER-->
<!--PRABHUPADA SECTION-->
<main class="container-fluid" style="background-color: #d3d3d3">
  <div class="container p-2">
    <!--Section: Main info-->
    <section class="mt-2 wow fadeIn">
      <!--Grid row-->
      <div class="row">
        <!--Grid column-->
        <div class="col-md-6 mb-4">
          <img
            src="{{ homeService.homePageContent[3]['file'] }}"
            class="img-fluid z-depth-1-half"
            alt=""
            data-aos="zoom-in"
          />
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div
          class="col-md-6 mb-4"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <!-- Main heading -->
          <h2 class="h2 mb-3">
            <!-- His Divine Grace A.C.Bhaktivedanta Swami Prabhupada -->
            {{ homeService.homePageContent[3]["title"] }}
          </h2>

          <h4 class="h4 mb-3">
            <i
              >Founder-Acarya of the International Society for Krishna
              consiousness</i
            >
          </h4>
          <p style="text-align: justify">
            {{ homeService.homePageContent[3]["details"] }}
            <!-- When he first arrived by freighter in New York City at the
            <strong> age of <i>69</i></strong
            >, Srila Prabhupada was practically penniless. Only after almost a
            year of great difficulty did he establish the International Society
            for Krishna Consciousness, in <strong>July of 1966</strong>. Before
            he passed away on <b>November 14, 1977</b> he had guided the Society
            and seen it grow to a world wide confederation of more than one
            hundred asramas, schools, temples, institutes and farm
            communities.ISKCON belongs to Gaudiya (refers to Bengal)
            Vaishnavism, a devotional tradition based on the teachings of
            Bhagavad-gita and Srimad-Bhagavatam. -->
          </p>

          <!-- CTA -->
          <a routerLink="/home/prabhupada" class="btn btn-primary btn-md"
            >Read more about Srila Prabhupada
            <i class="fas fa-arrow-right ml-1"></i>
          </a>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </section>
    <!--Section: Main info-->
  </div>
</main>
<!--/.PRABHUPADA SECTION-->
<!--OTHER TRAINING CENTERS-->
<main>
  <div class="container-fluid">
    <div class="container remove-container-padding" data-aos="fade-right">
      <hr class="my-2" />

      <!--Section: Main features & Quick Start-->
      <section>
        <h1
          class="h2 text-center mb-2"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          style="color: #22313f; font-weight: bolder"
        >
          Contact a Monk Near You
        </h1>

        <!--Grid row-->
        <div class="row wow fadeIn" *ngIf="hktcList">
          <!--Grid column-->
          <div
            class="col-md-4 remove-padding-images"
            *ngFor="let record of hktcList"
            data-aos="flip-down"
          >
            <a [routerLink]="['../training-centres', record.id]">
              <mdb-card>
                <mdb-card-img src="{{ record.profile_photo }}"></mdb-card-img>
                <mdb-card-body>
                  <mdb-card-title>
                    <h3 style="margin: 0px">
                      {{ record.name }} (<i>{{ record.temple.temple_name }}</i
                      >)
                    </h3>
                    <h4 style="color: #808080; margin: 0px">
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                      {{ record.locality }} (<i
                        >{{ record.temple.city }},
                        {{ record.temple.country }}</i
                      >)
                    </h4>
                    <button class="btn btn-outline-success btn-md">
                      View more
                      <i class="fas fa-arrow-right ml-1"></i>
                    </button>
                  </mdb-card-title>
                </mdb-card-body>
              </mdb-card>
            </a>
          </div>
          <div class="col-12" style="display: flex; justify-content: center">
            <a
              routerLink="../training-centres"
              class="btn btn-outline-success btm-md"
              data-aos="zoom-out-up"
            >
              View more training centers <i class="fas fa-arrow-right ml-1"></i>
            </a>
          </div>
        </div>
        <div class="row" *ngIf="!hktcList" data-aos="fade-right">
          <div
            class="col mb-5 mt-5"
            style="display: flex; justify-content: center"
          >
            <mat-spinner></mat-spinner>
          </div>
        </div>
        <!--/Grid row-->
      </section>
      <!--Section: Main features & Quick Start-->
    </div>
  </div>
</main>
<!--/.OTHER TRAINING CENTERS SECTION-->
<main class="container-fluid" style="background-color: #22313f">
  <div class="container">
    <section>
      <h1
        class="h2 text-center mb-2"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        style="color: #ffffff; font-weight: bolder"
      >
        {{ homeService.homePageContent[4]["title"] }}
      </h1>
      <div class="row">
        <div class="col-md-6 mb-4">
          <img
            src="{{ homeService.homePageContent[4]['file'] }}"
            class="img-fluid z-depth-1-half"
            alt=""
            data-aos="zoom-in"
          />
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div
          class="col-md-6 mb-4"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <!-- Main heading -->
          <h3 class="h3 mt-2" style="color: #ffffff; font-weight: bolder">
            <i>
              <!-- "This education insitution must be unique in the world, because
              nobody is interested to give their children spiritual
              education" -->
              {{ homeService.homePageContent[4]["details"] }}
            </i>
          </h3>
          <!-- <h3 class="h3" style="color: #ffffff; font-weight: bolder">
            Srila Prabhupada <i>(March 03, 1975)</i>
          </h3> -->
          <!-- CTA -->
          <a routerLink="../outreach" class="btn btn-outline-success btn-md"
            >Read more about the student outreach program
            <i class="fas fa-arrow-right ml-1"></i>
          </a>
        </div>
      </div>
    </section>
  </div>
</main>
<!--Main layout-->
<!--GALLERY SECTION-->
<main>
  <div class="container-fluid">
    <div class="container remove-container-padding" data-aos="fade-right">
      <hr class="my-2" />

      <!--Section: Main features & Quick Start-->
      <section>
        <h1
          class="h2 text-center mb-2"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          style="color: #22313f; font-weight: bolder"
        >
          Gallery
        </h1>

        <!--Grid row-->
        <div class="row wow fadeIn" *ngIf="galleryList">
          <!--Grid column-->
          <div
            class="col-md-4 remove-padding-images"
            *ngFor="let record of galleryList"
            data-aos="flip-down"
          >
            <mdb-card>
              <mdb-card-img src="{{ record.photo }}"></mdb-card-img>
              <mdb-card-body>
                <mdb-card-title>
                  <h3 style="margin: 0px">
                    {{ record.description }}
                  </h3>
                  <h4 style="color: #808080; margin: 0px">
                    {{ record.created_date | date: "full" }}
                  </h4>
                </mdb-card-title>
              </mdb-card-body>
            </mdb-card>
          </div>
          <div class="col-12" style="display: flex; justify-content: center">
            <a routerLink="/home/gallery">
              <button
                mdbBtn
                type="button"
                color="default"
                outline="true"
                mdbWavesEffect
                data-aos="zoom-out-up"
              >
                View more in gallery <i class="fas fa-arrow-right ml-1"></i>
              </button>
            </a>
          </div>
        </div>
        <div class="row" *ngIf="!galleryList" data-aos="fade-right">
          <div
            class="col mb-5 mt-5"
            style="display: flex; justify-content: center"
          >
            <mat-spinner></mat-spinner>
          </div>
        </div>
        <!--/Grid row-->
      </section>
      <!--Section: Main features & Quick Start-->
    </div>
  </div>
</main>
<!--/.GALLERY SECTION-->
<!-- OUR LOCATION -->
<main>
  <div class="container-fluid">
    <!--Section: Main features & Quick Start-->
    <section>
      <h1
        class="h2 text-center mb-2"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        style="color: #22313f; font-weight: bolder"
      >
        Our Location
      </h1>
    </section>
    <div class="row">
      <div class="col-12 remove-padding-images">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8324306565946!2d36.81558861467071!3d-1.2737499359716868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f172f1cfe67cf%3A0x1365ea1a355753dd!2sHare%20Krishna%20Training%20Centre!5e0!3m2!1sen!2ske!4v1613577915574!5m2!1sen!2ske"
          width="100%"
          height="450"
          frameborder="0"
          style="border: 0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
  </div>
</main>
