<div class="container-fluid">
  <div class="row">
    <div class="col header">
      <div class="header-section">
        <h1 class="header-title">About Us</h1>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <!-- <div class="container"> -->
  <div class="row" *ngIf="hktcData">
    <div class="col-12" style="padding: 0px">
      <img
        src="{{ hktcData.profile_photo }}"
        alt=""
        class="img-fluid banner-image"
        *ngIf="hktcData.profile_photo"
      />
    </div>
    <div
      class="col-12"
      [innerHtml]="hktcData.details"
      *ngIf="hktcData.details"
    ></div>
    <div class="col-12 no-data" *ngIf="!hktcData.details">
      <h2 class="no-data-txt">No record available</h2>
    </div>
  </div>
  <div class="row" *ngIf="!hktcData">
    <div class="col-12 no-data">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <!-- </div> -->
</div>
