<div class="container-fluid" style="padding: 0px">
  <div class="container-fluid header">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 class="title">Privacy policy for HKTC Monks</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="privacyData">
    <div class="row">
      <div class="col" [innerHtml]="privacyData"></div>
    </div>
  </div>
  <div class="container" *ngIf="!privacyData">
    <div class="row" style="display: flex; justify-content: center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
