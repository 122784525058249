import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events-details',
  templateUrl: './events-details.component.html',
  styleUrls: ['./events-details.component.scss'],
})
export class EventsDetailsComponent implements OnInit {
  eventsData;
  constructor() {}

  ngOnInit(): void {
    this.eventsData = history.state;
  }
}
