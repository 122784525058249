import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iskcon-management-details',
  templateUrl: './iskcon-management-details.component.html',
  styleUrls: ['./iskcon-management-details.component.scss'],
})
export class IskconManagementDetailsComponent implements OnInit {
  managementData;
  constructor() {}

  ngOnInit(): void {
    this.managementData = history.state;
  }
}
