import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { FestivalsService } from './festivals.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  festivalsList = [];
  eventsList = [];
  noData;
  pageSize = 12;
  page = 1;
  finished = false;
  notEmpty = true;
  notScrolly = true;
  constructor(
    private http: HttpClient,
    public spinner: NgxSpinnerService,
    public festivalService: FestivalsService
  ) {}

  ngOnInit(): void {
    this.getFestivalsList(this.pageSize, this.page);
    this.getEventsList(this.pageSize, this.page);
  }
  getFestivalsList(pageSize, page) {
    return this.http
      .get(
        environment.rootUrl +
          'festivals/?pageSize=' +
          pageSize +
          '&page=' +
          page
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.noData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.notEmpty = false;
          } else {
            this.festivalsList = this.festivalsList.concat(newPost);
            this.notScrolly = true;
          }
        },
        (error) => {
          this.notEmpty = false;
          this.spinner.hide();
        }
      );
  }
  getEventsList(pageSize, page) {
    return this.http
      .get(
        environment.rootUrl + 'events/?pageSize=' + pageSize + '&page=' + page
      )
      .subscribe(
        (res: any) => {
          const newPost = res['results'];
          this.noData = res;
          this.spinner.hide();
          if (newPost.length === 0) {
            this.notEmpty = false;
          } else {
            this.eventsList = this.eventsList.concat(newPost);
            this.notScrolly = true;
          }
        },
        (error) => {
          this.notEmpty = false;
          this.spinner.hide();
        }
      );
  }
  onScrollFestivals() {
    this.page += 1;
    if (this.notScrolly && this.notEmpty) {
      this.spinner.show();
      this.notScrolly = false;
      this.getFestivalsList(this.pageSize, this.page);
    }
  }
  onScrollEvents() {
    this.page += 1;
    if (this.notScrolly && this.notEmpty) {
      this.spinner.show();
      this.notScrolly = false;
      this.getEventsList(this.pageSize, this.page);
    }
  }
}
